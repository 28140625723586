/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-23 18:30:15
 * @LastEditTime: 2019-11-29 18:45:06
 * @LastEditors: Please set LastEditors
 */
import axios from 'axios';
import router from '../Routes'
import { Message } from 'element-ui';
import md5 from '../utils/md5';
import { getlocalStorage, setlocalStorage, remlocalStorage } from '../utils/localStorage'
// 使用vuex做全局loading时使用
import store from '@/store'

// const devBaseUrl = 'http://chefstation.hexj.xyz/'
const devBaseUrl = 'https://api.chefstation.com.hk/'
const proBaseUrl = 'https://api.chefstation.com.hk/'
let baseURL

process.env.NODE_ENV === 'development' ? baseURL = devBaseUrl : baseURL = proBaseUrl


export default function $axios(options) {
    return new Promise((resolve, reject) => {
        const service = axios.create({
            baseURL: baseURL,
            timeout: 10000
        })

        let token
        let lang
        let route_id
        let btn_id
        let auth_id_json
        let supplierUserInfo

        // request 拦截器
        service.interceptors.request.use(
            config => {
                token = getlocalStorage('suppliertoken')
                lang = getlocalStorage('lang') || 'hk'
                route_id = getlocalStorage("supplierroute_id");
                btn_id = getlocalStorage("supplierbtn_id");
                auth_id_json = getlocalStorage("supplierAuth_id_json");
                supplierUserInfo = getlocalStorage("supplierUserInfo");
                config.headers.lang = lang

                function sortNumber(a, b) {
                    return a - b
                }
                // token
                if (token && route_id && btn_id && auth_id_json && supplierUserInfo) {
                    config.headers.token = token
                    config.headers.authmd5 = md5(JSON.stringify(JSON.parse(route_id).sort(sortNumber)) + JSON.stringify(JSON.parse(btn_id).sort(sortNumber)) + JSON.stringify(JSON.parse(auth_id_json).sort(sortNumber)))
                } else {
                    const arr = ['/supplier/v1/getInvoiceInfo', '/supplier/v1/login', '/supplier/v1/printOrderPDF']
                    if (arr.indexOf(config.url) == -1) {
                        // 重定向到登录页面
                        router.push('/login')
                        Message.warning({
                            message: lang == 'en' ? 'Access failed, please login again' : lang == 'cn' ? '访问失败，请重新登陆' : '訪問失敗，請重新登陸'
                        })
                    }
                }
                return config
            },

            error => {
                // 请求错误时

                // 1. 判断请求超时
                if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {

                    return service.request(originalRequest); // 再重复请求一次
                }
                // 2. 需要重定向到错误页面
                // const errorInfo = error.response
                // console.log(errorInfo)
                // if (errorInfo) {
                //   error = errorInfo.data  // 页面那边catch的时候就能拿到详细的错误信息,看最下边的Promise.reject
                //   const errorStatus = errorInfo.status; // 404 403 500 ...
                //   router.push({
                //     path: `/error/${errorStatus}`
                //   })
                // }
                return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
            }
        )

        // response 拦截器
        service.interceptors.response.use(
            response => {
                let data;

                // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
                if (response.data == undefined) {
                    data = JSON.parse(response.request.responseText)
                } else {
                    data = response.data
                }

                //特殊错误码使用大弹框
                const errnoArr = [2028, 2041, 2129]
                // 根据返回的code值来做不同的处理
                if (errnoArr.indexOf(data.errno) > -1) {
                    Message.error({
                        duration: 0,
                        showClose: true,
                        dangerouslyUseHTMLString: true,
                        message: `<div style='line-height:1.5'><h5>[errno: ${data.errno}]</h5>${data.msg}</div>`
                    })
                }

                if (data.errno >= 2000 && data.errno <= 3000 && errnoArr.indexOf(data.errno) == -1) {
                    Message.warning(`${data.msg}[errno: ${data.errno}]`)
                    if (data.errno == 2122) {
                        localStorage.clear()
                        router.push('/login')
                    }
                }
                if (data.errno >= 1000 && data.errno < 2000) {
                    Message.warning({
                        message: lang == 'en' ? `Network error, please try again later![errno: ${data.errno}]` : `網絡錯誤請稍後重試！[errno: ${data.errno}]`
                    })
                }
                if (!data.data && data.errno == 0) {
                    const arr = ['/userChangeLang', '/getChatList', '/getChatRecord', '/clearAllInform', '/clearChatListBadge', '/getInform', '/clearChatInform']
                    let falg = true

                    arr.forEach((v, i) => {
                        if (v == response.config.url.substring(response.config.url.indexOf('v1/') + 2, response.config.url.length)) {
                            falg = false
                        }
                    })
                    if (falg) {
                        Message.success({
                            message: lang == 'en' ? 'Operation is successful！' : '操作成功'
                        })
                    }
                }
                return data

            },
            err => {
                if (String(err).indexOf('Network Error') > 0 || String(err).indexOf('timeout') > 0 || err.response.status == 400 || err.response.status == 404 || err.response.status == 500) {
                    Message.warning({
                        message: lang == 'en' ? 'Network error, please try again later!' : '網絡錯誤請稍後重試!'
                    })
                }

                if (store.state.loading) {
                    loading.close()
                    store.commit('closeLoading')
                }
                return Promise.reject(err) // 返回接口返回的错误信息
            }
        )

        // 请求处理
        service(options).then(res => {
            // if (!res.errno || res.errno == 0) {
            resolve(res)
            // }
            return false
        }).catch(error => {
            if (error.response.status == 403) {
                setlocalStorage('suppliertoken', error.response.headers.token)
                service(options).then(res => {
                    resolve(res)
                })
                return false
            } else if (error.response.status == 401) {
                localStorage.clear()
                Message.warning({
                    message: lang == 'en' ? 'Long time no operation, please login again!' : '長時間未操作，請重新登陸！'
                })
                router.push('/login')
            }
            reject(error)
        })
    })
}
