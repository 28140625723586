var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{class:{ sidebar: true, sidebarStatic: _vm.sidebarStatic, sidebarOpened: _vm.sidebarOpened }},[_vm._m(0),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticStyle:{"width":"50px","height":"50px","margin":"0 auto","border-radius":"50%","overflow":"hidden"}},[_c('img',{staticStyle:{"display":"block","width":"100%","height":"auto"},attrs:{"src":_vm.userInfo.avatar}})]),_c('span',{staticClass:"username"},[_vm._v(_vm._s(_vm.userInfo.user_name))]),(_vm.userInfo.is_superuser)?_c('span',{staticClass:"username"},[_vm._v(" "+_vm._s(_vm.$t('aboutus.09AE4A@primary_account'))+" ")]):_vm._e()]),_c('ul',{staticClass:"nav"},[_c('NavLink',{attrs:{"header":_vm.$t('supplier_submenu.12FC12@control_panel'),"link":"/app/dashboard","iconName":"fa-dashboard","index":"dashboard","isHeader":"","perm":8663}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('supplier_submenu.B77F3A@authority_management'),"link":"/app/power","iconName":"fa-balance-scale","index":"power","perm":_vm.hasRoutePerms(7553)
            ? 1
            : _vm.hasRoutePerms(6974)
            ? 1
            : _vm.hasRoutePerms(8033)
            ? 1
            : 0,"childrenLinks":[
          {
            perm: 7553,
            header: _vm.$t('restaurant_submenu.BA20D2@Privilege_grouping'),
            link: '/app/power/grouping'
          },
          {
            perm: 6974,
            header: _vm.$t('supplier_power_childUser.65D941@Subuser_management'),
            link: '/app/power/childUser'
          },
          {
            perm: 8033,
            header: _vm.$t('restaurant_submenu.65D701@Operation_record'),
            link: '/app/power/manageLog'
          }
        ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('supplier_submenu.AD3C65@commodity_management'),"link":"/app/supplier","iconName":"fa-shopping-cart","index":"supplier","perm":_vm.hasRoutePerms(5014) ? 1 : _vm.hasRoutePerms(8533) ? 1 : 0,"childrenLinks":[
          {
            perm: 5014,
            header: _vm.$t('supplier_supplier_produceManage.4E60F8@Management_products'),
            link: '/app/supplier/produceManage'
          } ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('aboutus.44863D@Report'),"link":"/app/reportAnalysis","iconName":"fa-line-chart","index":"reportAnalysis","perm":_vm.hasRoutePerms(15850480142113) ? 1 : _vm.hasRoutePerms(15850480847371) ? 1 : 0,"childrenLinks":[
          {perm: 15850480142113,header: _vm.$t('aboutus.44863D@Report'),link: '/app/report/WastageSummary'},
          {perm: 15850480847371,header: _vm.$t('aboutus.F12209@analysis'),link: '/app/report/anlyse'} ]}}),_c('NavLink',{attrs:{"header":_vm.$t('supplier_partner.475422@partner'),"link":"/app/restaurant","iconName":"fa-handshake-o","index":"partner","isHeader":"","perm":2464}}),_c('NavLink',{attrs:{"header":_vm.$t('supplier_order.E0C2CB@order_management'),"link":"/app/order","iconName":"fa-list-alt","index":"order","isHeader":"","perm":9109}}),_c('NavLink',{attrs:{"header":_vm.$t('invoice.AE64AD@menu'),"link":"/app/invoice","iconName":"fa-money","index":"panel","isHeader":"","perm":16143646427642}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('aboutus.D4D3E7@chat'),"link":"/app/chat","iconName":"fa-comments","index":"chat","perm":_vm.hasRoutePerms(6359) ? 1 : 0,"childrenLinks":[
          {
            perm: 6359,
            header: _vm.$t('aboutus.58431A@chatroom'),
            link: '/app/chat/chatroom'
          }
        ]}}),_c('NavLink',{attrs:{"activeItem":_vm.activeItem,"header":_vm.$t('supplier_submenu.99DB4A@Setting'),"link":"/app/setting","iconName":"fa-cog","index":"setting","perm":_vm.hasRoutePerms(8885) ? 1 : _vm.hasRoutePerms(2146) ? 1 : 0,"childrenLinks":[
          {
            perm: 8885,
            header: _vm.$t(
              'supplier_company_information.539097@company_information'
            ),
            link: '/app/setting/company'
          },
          {
            perm: 2146,
            header: _vm.$t(
              'supplier_TransactionSettings.2435B2@Transaction_settings'
            ),
            link: '/app/setting/underdrive'
          },
          {
            perm: 7710,
            header: _vm.$t(
              'supplier-setting-DistributionSet.0823DB@DistributionSet'
            ),
            link: '/app/setting/DistributionSet'
          }
        ]}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"side-title"},[_c('div',{staticClass:"project-logo"},[_c('img',{staticStyle:{"width":"90%"},attrs:{"src":require("../../assets/headLogo.png")}})])])}]

export { render, staticRenderFns }