/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-20 23:41:39
 * @LastEditTime: 2019-10-18 14:54:39
 * @LastEditors: Please set LastEditors
 */
import { getlocalStorage } from '../utils/localStorage'
//  btn
export function hasPermission(perm) {
  let hasPermission = false
  // let permissions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 745, 844, 633, 648];
  let permissions = JSON.parse(getlocalStorage('supplierbtn_id'))
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i] == perm) {
      hasPermission = true;
      break
    }
  }
  return hasPermission
}


// router
export function hasRoutePermission(perm) {
  let hasPermission = false
  let permissions = JSON.parse(getlocalStorage('supplierroute_id'))
  // let permissions = JSON.parse(this.$getlocalStorage('route_id'))

  // let permissions = [1, 3, 4, 5, 6, 7, 9, 10, 12, 13, 14, 15];
  for (let i = 0; i < permissions.length; i++) {
    if (permissions[i] === perm) {
      hasPermission = true;
      break
    }
  }

  return hasPermission
}
