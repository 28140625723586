/*
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-28 18:09:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_supplier\src\store\index.js
 */
import Vue from 'vue';
import Vuex from 'vuex';

import layout from './layout';
import websocket from './websocket';
import app from './modules/app'
import tab from './modules/tab'
import iframe from './modules/iframe'
import user from './modules/user'
import menu from './modules/menu'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    room_id: ''
  },
  mutations: {
    setRoomId(state, data) {
      state.room_id = data
    },
  },
  modules: {
    layout,
    app: app,
    tab: tab,
    iframe: iframe,
    user: user,
    menu: menu,
    websocket
  }
});
