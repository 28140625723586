<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-22 10:57:59
 * @LastEditTime: 2019-11-15 16:55:47
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div :class="{ root: true, chatOpen, sidebarClose, sidebarStatic }">
    <Sidebar :userInfo="userInfo" />

    <div class="wrap" style="height:100%;">
      <Header :userInfo="userInfo" v-on:modifyPass="modifyPass" />
      <el-scrollbar style="height:calc(100% - 50px);">
        <v-touch class="content" :swipe-options="{ direction: 'horizontal', threshold: 100 }">
          <div name="move" mode="out-in">
            <router-view />
          </div>
        </v-touch>
      </el-scrollbar>
    </div>
    <el-dialog
      :title="$t('public.2FC133@modify_pass')"
      :visible.sync="centerDialogVisible"
      width="50%"
    >
      <el-row>
        <el-col :span="18" :offset="3">
          <el-form ref="form" :model="dialogForm" label-width="150px">
            <el-form-item :label="$t('aboutus.06BB26@original_password')" prop="password">
              <el-input type="password" v-model="dialogForm.password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.3F9881@new_password')" prop="new_password">
              <el-input type="password" v-model="dialogForm.new_password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('aboutus.D904D5@repeat_password')" prop="repeat_password">
              <el-input type="password" v-model="dialogForm.repeat_password"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="centerDialogVisible = false">Cancel</el-button>
        <el-button size="mini" type="primary" @click="confirmPass">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";

import "./Layout.scss";

export default {
  name: "Layout",
  components: { Sidebar, Header },
  data() {
    return {
      centerDialogVisible: false,
      userInfo: "",
      dialogForm: {
        password: "",
        new_password: "",
        repeat_password: ""
      }
    };
  },
  methods: {
    modifyPass(data) {
      this.centerDialogVisible = data;
    },
    confirmPass() {
      let params = Object.assign({}, this.dialogForm);
      this.$http.changePwd(params).then(res => {
        console.log(res);
        if (res.errno == 0) {
          this.$refs["form"].resetFields();
          this.centerDialogVisible = false;
          s;
        }
      });
    },
    ...mapActions("layout", ["switchSidebar", "changeSidebarActive"])
  },
  computed: {
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic,
      chatOpen: state => state.chatOpen
    })
  },
  created() {
    this.userInfo = JSON.parse(this.$getlocalStorage("supplierUserInfo")) || "";
    if (!this.userInfo) {
      this.$message.warning(this.$t("aboutus.8FD945@please_log_in_again"));
      localStorage.clear();
      this.$router.push("/login");
    }
    const staticSidebar = JSON.parse(this.$getlocalStorage("sidebarStatic"));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      // setTimeout(() => {
      //   this.switchSidebar(true);
      //   this.changeSidebarActive(null);
      // }, 100);
    }
  }
};
</script>

<style src="./Layout.scss" lang="scss" />

