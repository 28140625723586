export default {
    namespaced: true,
    state: {
        chat_msg: '',
        chat_img: '',
        chat_list: '',
        chat_inform: '',
        req_sample: '',
        req_price: ''
    },
    mutations: {
        updataChatMsg(state, payload) {
            state.chat_msg = payload
        },
        updataChatImg(state, payload) {
            state.chat_img = payload
        },
        updataChatList(state, payload) {
            state.chat_list = payload
        },
        updataChatInform(state, payload) {
            state.chat_inform = payload
        },
        updataReqSample(state, payload) {
            state.req_sample = payload
        },
        updataReqPrice(state, payload) {
            state.req_price = payload
        }
    },
    actions: {
    },
    getters: {
    }
}