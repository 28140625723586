<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-29 18:43:24
 * @LastEditors: Please set LastEditors
 -->
<template>
  <nav :class="{ sidebar: true, sidebarStatic, sidebarOpened }">
    <header class="side-title">
      <div class="project-logo">
        <img style="width:90%;" src="../../assets/headLogo.png" />
      </div>
    </header>
    <div style="margin-top:20px;">
      <div style="width:50px;height:50px;margin:0 auto;border-radius:50%;overflow:hidden;">
        <img style="display:block;width:100%;height:auto;" :src="userInfo.avatar" />
      </div>
      <span class="username">{{ userInfo.user_name }}</span>
      <span class="username" v-if="userInfo.is_superuser">
        {{
        $t('aboutus.09AE4A@primary_account')
        }}
      </span>
    </div>
    <ul class="nav">
      <!-- 控制台 -->
      <NavLink
        :header="$t('supplier_submenu.12FC12@control_panel')"
        link="/app/dashboard"
        iconName="fa-dashboard"
        index="dashboard"
        isHeader
        :perm="8663"
      />
      <!-- 权限管理 -->
      <NavLink
        :activeItem="activeItem"
        :header="$t('supplier_submenu.B77F3A@authority_management')"
        link="/app/power"
        iconName="fa-balance-scale"
        index="power"
        :perm="
          hasRoutePerms(7553)
            ? 1
            : hasRoutePerms(6974)
            ? 1
            : hasRoutePerms(8033)
            ? 1
            : 0
        "
        :childrenLinks="[
          {
            perm: 7553,
            header: $t('restaurant_submenu.BA20D2@Privilege_grouping'),
            link: '/app/power/grouping'
          },
          {
            perm: 6974,
            header: $t('supplier_power_childUser.65D941@Subuser_management'),
            link: '/app/power/childUser'
          },
          {
            perm: 8033,
            header: $t('restaurant_submenu.65D701@Operation_record'),
            link: '/app/power/manageLog'
          }
        ]"
      />
      <!-- 產品管理 -->
      <NavLink
        :activeItem="activeItem"
        :header="$t('supplier_submenu.AD3C65@commodity_management')"
        link="/app/supplier"
        iconName="fa-shopping-cart"
        index="supplier"
        :perm="hasRoutePerms(5014) ? 1 : hasRoutePerms(8533) ? 1 : 0"
        :childrenLinks="[
          {
            perm: 5014,
            header: $t('supplier_supplier_produceManage.4E60F8@Management_products'),
            link: '/app/supplier/produceManage'
          },
          // {
          //   perm: 16358806978911,
          //   header: $t('SalesRankings.title'),
          //   link: '/app/supplier/salesRankings'
          // },
        ]"
      />
      <!-- {
            perm: 8533,
            header: $t(
              'supplier_supplier_goodsAttribute.49D281@commodity_property'
            ),
            link: '/app/supplier/goodsAttribute'
      }-->
      <!-- 找食材 -->
<!--      <NavLink-->
<!--        :active-item="activeItem"-->
<!--        :header="$t('tender.AE3454@name')"-->
<!--        link="/app/tender"-->
<!--        iconName="fa-puzzle-piece"-->
<!--        index="tender"-->
<!--        :perm="hasRoutePerms(16160946038897)? 1:0"-->
<!--        :childrenLinks="[-->
<!--          {perm: 16160947316898,header: $t('tender.D8E646@forum'),link: '/app/tender/forum'},-->
<!--        ]">-->
<!--      </NavLink>-->
      <!-- 报告 -->
      <NavLink
        :activeItem="activeItem"
        :header="$t('aboutus.44863D@Report')"
        link="/app/reportAnalysis"
        iconName="fa-line-chart"
        index="reportAnalysis"
        :perm="hasRoutePerms(15850480142113) ? 1 : hasRoutePerms(15850480847371) ? 1 : 0"
        :childrenLinks="[
          {perm: 15850480142113,header: $t('aboutus.44863D@Report'),link: '/app/report/WastageSummary'},
          {perm: 15850480847371,header: $t('aboutus.F12209@analysis'),link: '/app/report/anlyse'},
        ]"
      />
      <!-- 合作伙伴 -->
      <NavLink
        :header="$t('supplier_partner.475422@partner')"
        link="/app/restaurant"
        iconName="fa-handshake-o"
        index="partner"
        isHeader
        :perm="2464"
      />
      <!-- 評分管理 -->

<!--      <NavLink-->
<!--        :header="$t('rating.AED585@rating_manage')"-->
<!--        link="/app/RateManage"-->
<!--        iconName="fa-diamond"-->
<!--        index="RateManage"-->
<!--        isHeader-->
<!--        :perm="16167041061610"-->
<!--      />-->
      <!-- 订单管理 -->
      <NavLink
          :header="$t('supplier_order.E0C2CB@order_management')"
          link="/app/order"
          iconName="fa-list-alt"
          index="order"
          isHeader
          :perm="9109"
      />
      <!--   帳單管理   -->
      <NavLink
          :header="$t('invoice.AE64AD@menu')"
          link="/app/invoice"
          iconName="fa-money"
          index="panel"
          isHeader
          :perm="16143646427642">
      </NavLink>
      <!-- 聊天 -->
      <NavLink
        :activeItem="activeItem"
        :header="$t('aboutus.D4D3E7@chat')"
        link="/app/chat"
        iconName="fa-comments"
        index="chat"
        :perm="hasRoutePerms(6359) ? 1 : 0"
        :childrenLinks="[
          {
            perm: 6359,
            header: $t('aboutus.58431A@chatroom'),
            link: '/app/chat/chatroom'
          }
        ]"
      />
      <!-- 设置 -->
      <NavLink
        :activeItem="activeItem"
        :header="$t('supplier_submenu.99DB4A@Setting')"
        link="/app/setting"
        iconName="fa-cog"
        index="setting"
        :perm="hasRoutePerms(8885) ? 1 : hasRoutePerms(2146) ? 1 : 0"
        :childrenLinks="[
          {
            perm: 8885,
            header: $t(
              'supplier_company_information.539097@company_information'
            ),
            link: '/app/setting/company'
          },
          {
            perm: 2146,
            header: $t(
              'supplier_TransactionSettings.2435B2@Transaction_settings'
            ),
            link: '/app/setting/underdrive'
          },
          {
            perm: 7710,
            header: $t(
              'supplier-setting-DistributionSet.0823DB@DistributionSet'
            ),
            link: '/app/setting/DistributionSet'
          }
        ]"
      />
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions } from "vuex";
import isScreen from "@/core/screenHelper";
import NavLink from "./NavLink/NavLink";
import { hasRoutePermission } from "../../permission/index.js";
export default {
  name: "Sidebar",
  components: { NavLink },
  props: {
    userInfo: "",
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions("layout", ["changeSidebarActive", "switchSidebar"]),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split("/");
      paths.pop();
      this.changeSidebarActive(paths.join("/"));
    },
    // sidebarMouseEnter () {
    //   if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
    //     this.switchSidebar(false);
    //     this.setActiveByRoute();
    //   }
    // },
    // sidebarMouseLeave () {
    //   if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
    //     this.switchSidebar(true);
    //     this.changeSidebarActive(null);
    //   }
    // },
    hasRoutePerms: function (perm) {
      return hasRoutePermission(perm);
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapState("layout", {
      sidebarStatic: (state) => state.sidebarStatic,
      sidebarOpened: (state) => !state.sidebarClose,
      activeItem: (state) => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped />
