/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-27 15:53:30
 * @LastEditTime: 2019-11-26 15:26:04
 * @LastEditors: Please set LastEditors
 */
import store from '@/store'
import request from './request';
import { Loading, Message } from 'element-ui';


let options = {
    lock: store.state.loading,
    text: "加載中...",
    target: document.querySelector('.content') //设置加载动画区域
}


// 获取操作记录
export default {
    //上傳產品Excel
    uploadGoodsExcel(data) {
        return request({
            url: '/supplier/v1/uploadGoodsExcel',
            method: 'post',
            data
        })
    },
    //設定更新產品價格設定
    setUpgradePriceSetting(data) {
        return request({
            url: '/supplier/v1/setUpgradePriceSetting',
            method: 'post',
            data
        })
    },
    //暫時替代WS發送訊息
    sendMessage(data) {
        return request({
            url: '/supplier/v1/sendMessage',
            method: 'post',
            data
        })
    },
    //上傳pdf to image for OCR
    uploadPDF(data){
        return request({
            url: '/supplier/v1/uploadPDF',
            method: 'post',
            data
        })
    },
    //刪除聊天室對話
    delMessage(data){
        return request({
            url: '/supplier/v1/delMessage',
            method: 'post',
            data
        })
    },
    //取得產品排行榜清單
    getTopGoodsList(params) {
        return request({
            url: '/supplier/v1/getTopGoodsList',
            method: 'get',
            params
        })
    },
    //取得該筆訂單可以新增的產品
    getCanAddProductList(params) {
        return request({
            url: '/supplier/v1/getCanAddProductList',
            method: 'get',
            params
        })
    },
    //取得能顯示的餐廳清單
    getCanShowRestaurant(params){
        return request({
            url: '/supplier/v1/getCanShowRestaurant',
            method: 'get',
            params
        })
    },
    //修改評分狀態
    addOrder(data){
        return request({
            url: '/supplier/v1/addOrder',
            method: 'post',
            data
        })
    },
    //取得目前上架商品
    getCurrentGoodsList(params){
        return request({
            url: '/supplier/v1/getCurrentGoodsList',
            method: 'get',
            params
        })
    },
    //取得餐廳分店
    getRestaurantShop(params){
        return request({
            url: '/supplier/v1/getRestaurantShop',
            method: 'get',
            params
        })
    },
    //取得預覽產品資訊
    getPreviewProduct(params){
        return request({
            url: '/supplier/v1/getPreviewProduct',
            method: 'get',
            params
        })
    },
    //修改評分狀態
    modifyRatingStatus(data){
        return request({
            url: '/supplier/v1/modifyRatingStatus',
            method: 'post',
            data
        })
    },
    //取得評論清單
    getRatingList(params){
        return request({
            url: '/supplier/v1/getRatingList',
            method: 'get',
            params
        })
    },
    //新增回覆至該Tender
    addCommentToTender(data) {
        return request({
            url: '/supplier/v1/addCommentToTender',
            method: 'post',
            data
        })
    },
    //取得找食材討論串
    getTenderInfo(params) {
        return request({
            url: '/supplier/v1/getTenderInfo',
            method: 'get',
            params
        })
    },
    //取得找食材論壇清單
    getTenderForms(params) {
        return request({
            url: '/supplier/v1/getTenderForms',
            method: 'get',
            params
        })
    },
    //更改訂單運費
    editOrderDeliverFee(data){
        return request({
            url: '/supplier/v1/editOrderDeliverFee',
            method: 'post',
            data
        })
    },
    //取得發票資訊
    getInvoiceInfo(params){
        return request({
            url: '/supplier/v1/getInvoiceInfo',
            method: 'get',
            params
        })
    },
    //取得帳單清單
    getInvoiceList(params){
        return request({
            url: '/supplier/v1/getInvoiceList',
            method: 'get',
            params
        })
    },
    //更新訂單評分
    updateSellerOrderScore(data){
        return request({
            url: '/supplier/v1/updateSellerOrderScore',
            method: 'post',
            data
        })
    },
    /**
     * 刪除所有產品
     * */
    deleteAllProducts(data) {
        return request({
            url: '/supplier/v1/deleteAllProducts',
            method: 'post',
            data
        })
    },
    // 权限管理
    // dashboard
    getChart(params) {
        return request({
            url: '/supplier/v1/getConsoleData',
            method: 'get',
            params
        })
    },
    // grouping 权限分组


    //获取权限JSON列表
    getAuthJsonList(params) {
        return request({
            url: '/supplier/v1/getAuthJsonList',
            method: 'get',
            params
        })
    },
    // 获取权限组列表
    groupingGetdata(params) {

        return request({
            url: '/supplier/v1/getAuthGroupList',
            method: 'get',
            params
        })
    },
    // 获取权限组列表
    groupingGetInfo(params) {
        return request({
            url: '/supplier/v1/getAuthGroupInfo',
            method: 'get',
            params
        })
    },
    // 修改
    updateAuthGroup(data) {
        return request({
            url: '/supplier/v1/updateAuthGroup',
            method: 'post',
            data
        })
    },
    // 新增
    addAuthGroup(data) {
        return request({
            url: '/supplier/v1/addAuthGroup',
            method: 'post',
            data
        })
    },
    // 删除
    delAuthGroup(data) {
        return request({
            url: '/supplier/v1/delAuthGroup',
            method: 'post',
            data
        })
    },
    // 用户
    // 登录
    login(data) {
        return request({
            url: '/supplier/v1/login',
            method: 'post',
            data
        })
    },
    // 登出
    logout(data) {
        return request({
            url: '/supplier/v1/logout',
            method: 'post',
            data
        })
    },


    // childUser  子用户


    // 获取列表
    getUserList(params) {
        return request({
            url: '/supplier/v1/getUserList',
            method: 'get',
            params
        })
    },
    // 锁定/解锁
    updateUserStat(data) {
        return request({
            url: '/supplier/v1/updateUserStat',
            method: 'post',
            data
        })
    },
    // 删除
    delUser(data) {

        return request({
            url: '/supplier/v1/delUser',
            method: 'post',
            data
        })
    },
    // 重置
    resetUserPwd(data) {
        return request({
            url: '/supplier/v1/resetUserPwd',
            method: 'post',
            data
        })
    },
    // 获取选择器
    groupSelector(params) {
        return request({
            url: '/supplier/v1/groupSelector',
            method: 'get',
            params
        })
    },
    // 修改时获取信息
    getUserInfo(params) {
        return request({
            url: '/supplier/v1/getUserInfo',
            method: 'get',
            params
        })
    },
    //新增
    addSubUser(data) {
        return request({
            url: '/supplier/v1/addSubUser',
            method: 'post',
            data
        })
    },
    //更新
    updateUser(data) {
        return request({
            url: '/supplier/v1/updateUser',
            method: 'post',
            data
        })
    },
    //修改密码
    changePwd(data) {
        return request({
            url: '/supplier/v1/changePwd',
            method: 'post',
            data
        })
    },


    // manageLog 操作记录

    getOperatingList(params) {
        store.commit('openLoading')
        return request({
            url: '/supplier/v1/getOperatingList',
            method: 'get',
            params
        })
    },
    // 删除记录
    delOperating(data) {
        return request({
            url: '/supplier/v1/delOperating',
            method: 'post',
            data
        })
    },

    // 供应商产品


    // 供应商产品表格
    getProduct(params) {
        return request({
            url: '/supplier/v1/getGoodsList',
            method: 'get',
            params
        })
    },
    addProduct(data) {
        return request({
            url: '/supplier/v1/addGoods',
            method: 'post',
            data
        })
    },

    queryProduct(params) {
        return request({
            url: '/supplier/v1/getGoodsInfo',
            method: 'get',
            params
        })
    },
    modifyProduct(data) {
        return request({
            url: '/supplier/v1/updateGoods',
            method: 'post',
            data
        })
    },
    deleteProduct(data) {
        return request({
            url: '/supplier/v1/delGoods',
            method: 'post',
            data
        })
    },


    typeSelect(params) {
        return request({
            url: '/restaurant/v1/goodsCategorySelector',
            method: 'get',
            params
        })
    },

    // 批量上下架
    massUp(data) {
        return request({
            url: '/supplier/v1/b2bGoodsUpshelf',
            method: 'post',
            data
        })
    },

    unitSelect(params) {
        return request({
            url: '/supplier/v1/goodsUnitSelector',
            method: 'get',
            params
        })
    },
    productProp(params) {
        return request({
            url: '/supplier/v1/goodsSpecSelector',
            method: 'get',
            params
        })
    },

    propList(params) {
        return request({
            url: '/supplier/v1/generateSkuList',
            method: 'get',
            params
        })
    },

    // excel
    // 批量添加商品

    // 下载产品excel模版
    downloadCustomGoodsTemplate(params) {
        return request({
            url: '/supplier/v1/downloadGoodsTemplate',
            method: 'get',
            params,
            responseType: 'blob'
        })
    },
    // 下载产品excel
    downloadCustomGoodsExcel(params) {
        return request({
            url: '/supplier/v1/downloadGoodsExcel',
            method: 'get',
            params,
            responseType: 'blob'
        })
    },
    // 批量添加产品
    addBatchCustomGoods(data) {
        return request({
            url: '/supplier/v1/addBatchGoods',
            method: 'POST',
            data,
        })
    },

    // 下載批量修改Excel
    downloadAwaitGoodsExcel(params) {
        return request({
            url: '/supplier/v1/downloadAwaitGoodsExcel',
            method: 'get',
            params,
            responseType: 'blob'
        })
    },
    // 批量修改商品
    updateBatchGoods(data) {
        return request({
            url: '/supplier/v1/updateBatchGoods',
            method: 'post',
            data,
        })
    },


    // 商品属性


    // 获取列表数据
    getGoodsSpecList(params) {
        return request({
            url: '/supplier/v1/getGoodsSpecList',
            method: 'get',
            params,
        })
    },
    // 删除记录
    delGoodsSpec(data) {
        return request({
            url: '/supplier/v1/delGoodsSpec',
            method: 'post',
            data,
        })
    },
    // 新增
    addGoodsSpec(data) {
        return request({
            url: '/supplier/v1/addGoodsSpec',
            method: 'post',
            data,
        })
    },
    // 修改时获取数据
    getGoodsSpecInfo(params) {
        return request({
            url: '/supplier/v1/getGoodsSpecInfo',
            method: 'get',
            params,
        })
    },
    // 修改
    updateGoodsSpec(data) {
        return request({
            url: '/supplier/v1/updateGoodsSpec',
            method: 'post',
            data,
        })
    },


    // 合作伙伴

    // 获取列表数据
    getPartnerList(params) {
        return request({
            url: '/supplier/v1/getPartnerList',
            method: 'get',
            params
        })
    },
    // 新增
    addPartner(data) {
        return request({
            url: '/supplier/v1/addPartner',
            method: 'post',
            data
        })
    },
    // 修改信息
    getPartnerInfo(params) {
        return request({
            url: '/supplier/v1/getPartnerInfo',
            method: 'get',
            params
        })
    },
    // 修改
    updatePartner(data) {
        return request({
            url: '/supplier/v1/updatePartner',
            method: 'post',
            data
        })
    },
    // 删除
    delPartner(data) {
        return request({
            url: '/supplier/v1/delPartner',
            method: 'post',
            data
        })
    },
    // 選擇器
    restaurantSelector(params) {
        return request({
            url: '/supplier/v1/restaurantSelector',
            method: 'get',
            params
        })
    },
    // 供应商選擇器
    supplierGoodsSelector(params) {
        return request({
            url: '/supplier/v1/supplierGoodsSelector',
            method: 'get',
            params
        })
    },
    // 获取合作产品
    getPartnerGoods(params) {
        return request({
            url: '/supplier/v1/getPartnerGoods',
            method: 'get',
            params
        })
    },
    // 新增合作产品
    addPartnerGoods(data) {
        return request({
            url: '/supplier/v1/addPartnerGoods',
            method: 'post',
            data
        })
    },
    // 更新合作产品
    updatePartnerGoods(data) {
        return request({
            url: '/supplier/v1/updatePartnerGoods',
            method: 'post',
            data
        })
    },
    // 删除合作产品
    delPartnerGoods(data) {
        return request({
            url: '/supplier/v1/delPartnerGoods',
            method: 'post',
            data
        })
    },
    // 获取库存
    getB2BGoodsAttr(params) {
        return request({
            url: '/supplier/v1/getB2BGoodsAttr',
            method: 'get',
            params
        })
    },




    //订单管理
    getShopSelector(params) {
        return request({
            url: '/supplier/v1/orderShopSelector',
            method: 'get',
            params
        })
    },
    getOrderList(params) {
        return request({
            url: '/supplier/v1/getOrderList',
            method: 'get',
            params
        })
    },
    // 订单详情
    getOrderDetail(params) {
        return request({
            url: '/supplier/v1/getOrderDetail',
            method: 'get',
            params
        })
    },

    //删除

    delOrderGoods(data) {
        return request({
            url: '/supplier/v1/delOrderGoods',
            method: 'post',
            data
        })
    },
    // 修改订单状态
    updateOrderStat(data) {
        return request({
            url: '/supplier/v1/updateOrderStat',
            method: 'post',
            data
        })
    },
    // 更改订单支付状态
    updateOrderPayStat(data) {
        return request({
            url: '/supplier/v1/updateOrderPayStat',
            method: 'post',
            data
        })
    },
    //打印pdf
    printOrderPDF(params) {
        return request({
            url: '/supplier/v1/printOrderPDF',
            method: 'get',
            params
        })
    },
    inputInvoice(data) {
        return request({
            url: '/supplier/v1/inputInvoice',
            method: 'post',
            data
        })
    },








    // 设置

    // 公司信息

    // 获取公司信息
    getCompanyInfo(params) {
        return request({
            url: '/supplier/v1/getCompanyInfo',
            method: 'get',
            params
        })
    },
    // 修改公司信息
    updateCompany(data) {
        return request({
            url: '/supplier/v1/updateCompany',
            method: 'post',
            data
        })
    },
    // 香港行政分区选择器
    HKDistrictSelector(params) {
        return request({
            url: '/restaurant/v1/HKDistrictSelector',
            method: 'get',
            params
        })
    },

    // 交易设置

    // 获取交易信息
    getTradeSetting(params) {
        return request({
            url: '/supplier/v1/getTradeSetting',
            method: 'get',
            params
        })
    },
    // 修改交易信息
    updateTradeSetting(data) {
        return request({
            url: '/supplier/v1/updateTradeSetting',
            method: 'post',
            data
        })
    },

    /*    配送设定    */

    // 获取配送区域列表
    getDistributionArea(params) {
        return request({
            url: '/supplier/v1/getDistributionArea',
            method: 'get',
            params
        })
    },
    // 添加配送区域
    addDistributionArea(data) {
        return request({
            url: '/supplier/v1/addDistributionArea',
            method: 'post',
            data
        })
    },
    // 获取配送区域信息
    getDistributionAreaInfo(params) {
        return request({
            url: '/supplier/v1/getDistributionAreaInfo',
            method: 'get',
            params
        })
    },
    // 修改配送区域
    updateDistributionArea(data) {
        return request({
            url: '/supplier/v1/updateDistributionArea',
            method: 'post',
            data
        })
    },
    // 删除配送区域
    delDistributionArea(data) {
        return request({
            url: '/supplier/v1/delDistributionArea',
            method: 'post',
            data
        })
    },

    //聊天

    // 获取聊天列表
    getChatList(params) {
        return request({
            url: '/supplier/v1/getChatList',
            method: 'get',
            params
        })
    },
    // 获取聊天记录
    getChatRecord(params) {
        return request({
            url: '/supplier/v1/getChatRecord',
            method: 'get',
            params
        })
    },
    // 获取聊天记录
    clearChatListBadge(data) {
        return request({
            url: '/supplier/v1/clearChatListBadge',
            method: 'post',
            data
        })
    },
    // 获取聊天通知
    getInform(params) {
        return request({
            url: '/supplier/v1/getInform',
            method: 'get',
            params
        })
    },
    // 清除聊天通知
    clearChatInform(data) {
        return request({
            url: '/supplier/v1/clearChatInform',
            method: 'post',
            data
        })
    },
    // 清除聊天通知
    clearAllInform(data) {
        return request({
            url: '/supplier/v1/clearAllInform',
            method: 'post',
            data
        })
    },
    //切换语言
    userChangeLang(data) {
        return request({
            url: '/supplier/v1/userChangeLang',
            method: 'post',
            data
        })
    },




    // 报告分析
    getTotalSales(params) {
        return request({
            url: '/supplier/v1/getTotalSalesAmount',
            method: 'get',
            params
        })
    },
    getProductSales(params) {
        return request({
            url: '/supplier/v1/getProductSalesChart',
            method: 'get',
            params
        })
    },
    getProductDiff(params) {
        return request({
            url: '/supplier/v1/getProductSalesDiff',
            method: 'get',
            params
        })
    },


    // 报告
    // 获取餐厅订单
    getRestaurantOrderList(params) {
        return request({
            url: '/supplier/v1/getRestaurantOrderList',
            method: 'get',
            params
        })
    },
    // 导出餐厅订单
    exportRestaurantOrderExcel(params) {
        return request({
            url: '/supplier/v1/exportRestaurantOrderExcel',
            method: 'get',
            params,
            responseType: 'blob'
        })
    },
    // 获取未付款订单
    getNotPaidOrderList(params) {
        return request({
            url: '/supplier/v1/getNotPaidOrderList',
            method: 'get',
            params
        })
    },
    // 导出未付款订单
    exportNotPaidOrderExcel(params) {
        return request({
            url: '/supplier/v1/exportNotPaidOrderExcel',
            method: 'get',
            params,
            responseType: 'blob'
        })
    },
    // 获取会计报告
    getAccountantList(params) {
        return request({
            url: '/supplier/v1/getAccountantList',
            method: 'get',
            params
        })
    },
    // 导出会计报告PDF
    exportAccountantPDF(params) {
        return request({
            url: '/supplier/v1/exportAccountantPDF',
            method: 'get',
            params
        })
    },
}
