<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-29 18:45:57
 * @LastEditors: Please set LastEditors
 -->
<template>
  <router-view />
</template>

<script>
import { log } from "util";
import { mapState, mapMutations } from "vuex";
export default {
  name: "App",
  methods: {
    ...mapMutations("websocket", [
      "updataChatMsg",
      "updataChatImg",
      "updataChatList",
      "updataChatInform",
      "updataReqSample",
      "updataReqPrice"
    ]),
    onMessage(msg) {
      let token = this.$getlocalStorage("suppliertoken");
      let lang = this.$getlocalStorage("lang") || "hk";
      const data = JSON.parse(msg.data).data;
      if (JSON.parse(msg.data).errno === 1107) {
        console.log("长连接长时间未操作重新登录", data);
        this.$remlocalStorage("suppliertoken");
        this.$message.warning({
          message:
            this.$getlocalStorage("lang") == "en"
              ? "Long time no operation, please login again!"
              : this.$getlocalStorage("lang") == "cn"
              ? "长时间未操作，请重新登录！"
              : "長時間未操作，請重新登錄！"
        });
        this.$router.push("/login");
        return;
      } else if (JSON.parse(msg.data).errno === 2122) {
        localStorage.clear();
        this.$router.push("/login");
        this.$message.warning(
          `${JSON.parse(msg.data).msg}[errno: ${JSON.parse(msg.data).errno}]`
        );
        return;
      }
      if (data.push_type == "ping") {
        // console.log(data);
        if (this.global.websocket && this.global.websocket.readyState == 1) {
          let params = {
            push_type: "pong",
            room_id: "",
            content: "",
            code: ""
          };
          this.global.websocketsend(params);
          console.log("心跳包");
        }
      } else if (data.push_type == "chat_msg") {
        // console.log('消息', data)
        this.updataChatMsg(data);
      } else if (data.push_type == "chat_img") {
        // console.log('图片', data)
        this.updataChatImg(data);
      } else if (data.push_type == "chat_list") {
        // console.log('列表', data)
        this.updataChatList(data);
      } else if (data.push_type == "chat_inform") {
        // console.log('通知', data)
        this.updataChatInform(data);
      } else if (data.push_type == "req_sample") {
        // console.log('样本', data)
        this.updataReqSample(data);
      } else if (data.push_type == "req_price") {
        // console.log('报价', data)
        this.updataReqPrice(data);
      } else if (data.push_type == "refresh_token") {
        // console.log('刷新token', data)
        this.$setlocalStorage("suppliertoken", data.push_data);
        // this.global.FirstConnection();
      }
    }
  },
  beforeCreate() {
    const currentPath = this.$router.history.current.path;
    if (currentPath === "/" || currentPath === "/index") {
      // this.$router.push("/app/dashboard");
    }
  },
  created() {
    if (Boolean(this.$getlocalStorage("suppliertoken"))) {
      this.global.initWebSocket();
      this.global.websocketonmessage(this.onMessage);
    }
  },
  updated() {
    let lang = this.$getlocalStorage("lang") || "hk";

    this.$i18n.locale = lang;
  }
};
</script>

<style src="./styles/theme.scss" lang="scss" />
