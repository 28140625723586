/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-20 20:35:12
 * @LastEditTime: 2019-10-10 11:32:54
 * @LastEditors: Please set LastEditors
 */
/*
*封装函数 downLoadFile.js
*params:  
*data:二进制文件
*/
const install = function (Vue, options) {
    Vue.prototype.downloadFile = function (data) {
        if (!data) {
            return
        }
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'excel.xlsx')
        document.body.appendChild(link)
        link.click()
    };
    Vue.prototype.downloadFileByName = function (url, fileName) {
        if (!url) {
            return
        }
        let link = document.createElement('a')
        link.download = fileName
        link.href = url
        link.style.display = "none"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    };
}
export default install