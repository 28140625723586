/*
 * @Author: your name
 * @Date: 2019-08-31 18:57:10
 * @LastEditTime: 2019-11-25 20:21:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\i18n\lang\en.js
 */
import enLocale from "element-ui/lib/locale/lang/en";

export default {
    ...enLocale,
    "SalesRankings":{
        "title":"Sales Rankings",
        "rankings": "Rankings",
        "viewMore": "View More",
    },
    "hidden_or_show":{
        "FEA51D@show_label":"Show to which restaurants to see",
        "AD5E46@tip":"When no restaurant is selected, it will be visible to all restaurants",
    },
    "product_delivery":{
        'F5EA13@product_delivery':"Product Delivery",
        'AD58EF@supplier_direct_shipping':"Supplier Direct Shipping",
        'F5E1A3@in_store_pickup':"In-store Pickup",
    },
    "tender": {
        "AE3454@name": "Tender",
        "D8E646@forum": "Forum",
        "E1232D@NoReply": "No Reply",
        "32DE21@Last_reply_time": "Last reply time",
        "E3866A@join_reply":"Add Comment",
    },
    "invoice": {
        "BA1FF5@free": "Free",
        "AE5D11@manage_invoice": "Manage Invoice",
        "AE64AD@menu": "Invoice Panel",
        "F9100D@invoice_date": "Invoice Date",
        "0DE312@invoice_number": "Invoice Number",
        "A886987@gmv": "GMV",
        "EA5264@cost_ratio": "%",
        "9E1612@invoice_amount": "Invoice Amount",
        "84D521@pay_status": "Payment Status",
        "ED8512@click_to_see_invoice": "Click to see invoice",
        "F5D21D@unpaid": "Unpaid",
        "A8312E@paid": "Paid"
    },
    "rating": {
        "A6785E@rate_product": "Rate Product",
        "B8D531@rate_title": "Please rate your Purchase!",
        "FA5648@rate_comment": "Give some comments!",
        "AED585@rating_manage":"Rate Manage",
        "ED2551@product_reviews":"Product Reviews",
        "ADE215@top":"Top",
        "ED215F@bottom":"Bottom",
        "D54EA1@sortBy":"Sort By",
        "AE2121@filterBy":"Filter by",
        "BF215E1@All_Reviews":"All Reviews",
        "D545F1@Display_Reviews":"Display Reviews",
        "F21565@Not_Display_Reviews":"Not Display Reviews",
        "A12E51@one_star_only":"1 Star Only",
        "A12E52@two_star_only":"2 Star Only",
        "A12E53@three_star_only":"3 Star Only",
        "A12E54@four_star_only":"4 Star Only",
        "A12E55@five_star_only":"5 Star Only",
        "E2DF56@all":"All",
        "F54645@filter":"Filter",
        "B25ED5@Approve":"Approve",
        "A2575E@Hide":"Hide",
    },
    "backend_authrity_authrityList": {
        "A7F8C9@backend_tab": "Backend",
        "C90CD7@restaurant_tab": "Restaurant",
        "85EDE9@supplier_tab": "Supplier",
        "B2EBC3@authrity_id": "Authrity Id",
        "1DB5E9@simplified_Chinese": "Simplified Chinese",
        "A75174@Traditional_Chinese": "Traditional Chinese",
        "B25958@english": "English",
        "D22040@router": "Router",
        "468002@api": "API",
        "12349D@type": "Type",
        "0310FF@Superior": "Superior"
    },
    "backend_authrity_grouping": {
        "31BA13@grouping_id": "Group ID",
        "5EDDBF@grouping_name": "Permission Group",
        "4A9FA6@isSuper": "Superadmin",
        "26BE7D@admin": "Admin",
        "28C052@store": "Branch",
        "B3402A@edit_name": "Please fill in the group name",
        "F1B23F@edit_role": "Please Select Roles",
        "422561@edit_store": "Please Select Branch"
    },
    "backend_authrity_user": {
        "9A4F2F@username": "Username",
        "8319AA@nickname": "Nickname",
        "CA6925@avatar": "Profile Picture",
        "29E7EE@last_ip": "Last Login IP",
        "BAF37C@last_login_time": "Last Login Time",
        "07D89C@status": "Status",
        "BBA818@grouping_id": "Staff Type",
        "948EEB@password": "Password",
        "B5AC7E@isSuper": "Super Admin"
    },
    "backend_authrity_operationLog": {
        "8CF925@operationUser": "User",
        "3531D8@Controller": "Controller",
        "F5AD79@methods": "Methods",
        "AC377D@operation_info": "Usage Info",
        "E3B71C@operation_time": "Operation Time"
    },
    "public": {
        "not_remind_again":"Not Remind again",
        "bank_info":"Bank Information",
        "del_message":"Want to delete the message？",
        "FE11E4@add_goods":"Add Product",
        "ADF5E@email_tip":'There are multiple Emails, you can separate them by "comma" / "semicolon" or "newline""',
        "AAE565@is_heloneself":"Provide Self-collection",
        "E123AEF@add_order": "Add Order",
        "DC9752@btn_add": "Add New",
        "B6D57F@btn_modify": "Edit",
        "1FBB5E@btn_delete": "Delete",
        "2AE651@preview": "預覽",
        "2C74E6@btn_delete_more": "Batch Deletion",
        "711B38@serial_number": "Serial Number",
        "03EA09@operation": "Action",
        "25F6B0@tips": "Cannot Edit Permission or Delete Superadmin",
        "D17BCA@created_time": "Date",
        "E1A718@update_time": "Update Time",
        "564C21@unlock": "Unlock",
        "DBC110@locked": "Locked",
        "72A46B@reset_pass": "Reset Password",
        "A738BA@update": "Update",
        "B3669C@show": "Show",
        "8B9314@hide": "Hide",
        "4ABAB1@loading": "Loading",
        "84FA27@no_pictures": "No Pictures",
        "549A91@edit": "Edit",
        "3B45A2@confirm": "Confirm",
        "836FD4@cancel": "Cancel",
        "D74E99@simplified_chinese": "Simplified Chinese",
        "51D01B@traditional_chinese": "Traditional Chinese",
        "154BAB@english": "English",
        "A82133@username": "Username",
        "BB249F@supplier_name": "Supplier",
        "BFFB0F@creation_time": "Date",
        "97656C@Download_the_product_excel_template": "Download Excel Template",
        "6796E1@Download_product_excel": "Export to Excel",
        "37249B@pload_supplier_products": "Mass Upload",
        "507DF9@new_attribute": "New Attribute",
        "C82510@order_amount": "Order Amount",
        "180B5F@Payment_status": "Payment Status",
        "83746D@download_excel": "Download Template",
        "32355B@download_excel": "Export to Excel",
        "8D75D5@Hong_Kong_Administrative_Region": "District",
        "93EC9B@upload": "Upload Excel",
        "417BDC@E-mail": "Email",
        "821DEE@E-mail_order": "Order Notification E-Mail",
        "A98F12@E-mail_message": "New Message Notification E-Mail",
        "6EF123@E-mail_invoice": "Invoice Email",
        "2E733D@Cash_on_delivery": "Cash on Delivery",
        "5D8689@Delayable_payment": "Credit Term",
        "CC56D2@Designated_monthly_date": "Monthly Settlement",
        "653358@view": "View",
        "B8C509@Order_user": "Order User",
        "427B43@Minimum_amount": "Minimum Amount",
        "6D13C6@delete_tip": "Confirm Deletion?",
        "E91E1D@addr_hk": "Address(Chinese)",
        "966929@addr_en": "Address(English)",
        "4BF093@desc_hk": "Description(Chinese)",
        "90B2DC@desc_en": "Description(English)",
        "1A3EBC@save": "Save",
        "553159@tips": "Tips",
        "B6E56E@no_attr": "No Specification",
        "707A1E@logout": "Logout",
        "2FC133@modify_pass": "Change Password",
        "5AD435@Total": "Total Amount Payable (including Delivery Fee)",
        "AE2731@select_product": "Please Select  Product",
        "733AA9@Network": "Network error, please try again later!",
        "4F36DD@Operation_successful": "Operation Successfully！",
        "4A5563@min_number": "Minimum Purchase Quantity",
        "64203E@to_be_determined": "Pending Confirmation",
        "471669@Confirmed_order": "Confirmed",
        "8E9DAD@Some_accept": "Some Accepted",
        "4E63B1@unread": "Unread"
    },
    "placeholder": {
        "524620@start_time": "Start Time",
        "ABB7CF@end_time": "End Time",
        "C209ED@select": "Please Select",
        "9A92DD@username": "Username",
        "F0DDC0@select": "Please Select",
        "3F6A37@supplier": "Select Supplier",
        "D4E968@order_status": "Order Status",
        "5C894A@order_code": "Order No.",
        "7C1BF7@search_key": "Search for Products or Keywords",
        "F8EA00@select_type": "Choose Type",
        "D20BE9@Please_input": "Please Input"
    },
    "backend_submenu": {
        "ABAAF2@dashboard": "Dashboard",
        "ED96C7@authrity": "Permissions Control",
        "4A302E@authrityList": "Permission List",
        "21BF37@grouping": "Permission Group",
        "61A3ED@user": "Username",
        "6A0B0E@Operation_record": "Usage Record",
        "0FED78@config": "Config",
        "0265E5@Advertisement": "Advertisement",
        "157A9D@platform_Info": "Platform Information",
        "4F09AA@index": "Home  Category",
        "49DFF3@integral": "Integral",
        "AD8E7D@Merchant_man": "Merchant Management",
        "411879@restaurant_list": "Restaurant List",
        "BE2794@supplier_list": "Supplier List",
        "100AE5@Merchant_user_management": "Merchant User",
        "526167@restaurant_user_man": "Restaurant User",
        "92671D@supplier_user_man": "Supplier User",
        "96B8E2@Product_classification": "Category",
        "A15264@order_manage": "Order Managenent",
        "EF42B5@email_list": "Email List"
    },
    "backend_config_Advertisement": {
        "0672CC@ad_id": "Advertisement ID",
        "D34399@ad_type": "Advertisement Type",
        "88A5B8@ad_title": "Title",
        "88A6D5@ad_url": "Jump link",
        "46F8F7@isShow": "open or not",
        "1E4172@sort": "Sort",
        "8A317E@photo": "Advertising Picture",
        "23FE59@title_en": "Title(English)",
        "005381@title_hk": "Title(Chinese)",
        "EA667C@top_banner": "First Page Top Carousel",
        "136CD4@bottom_banner": "First Page Bottom Carousel",
        "778D25@med_banner": "4 Advertisements in the middle of the front page"
    },
    "backend_config_platformInfo": {
        "837359@email": "Email",
        "936621@phone": "Contact Number",
        "607689@Copyright": "Copyright",
        "D6C85F@bottomLogo": "Logo at the bottom of the home page"
    },
    "backend_config_home": {
        "1DC9DD@classify_id": "Category ID",
        "9266EE@classify_photo": "Category Pictures",
        "47380E@sort": "Sort",
        "966A47@classify_name": "Category  Name",
        "A7684E@isShow": "Show or not"
    },
    "backend_config_integral": {
        "BEC1AD@bonus_rate": "Bonus Point Proportion",
        "0B80C2@deduce_rate": "Percentage of Points Deducted",
        "160EFC@isBonus": "Whether to open bonus points"
    },
    "backend_shop_supplierList": {
        "8F71F5@company_id": "Company ID",
        "995038@company_name": "Company Name",
        "E919F3@super_user": "Company Super User",
        "5A75EE@Contact_person": "Contact Person",
        "F9618A@company_email": "Company Email",
        "65868F@company_phone": "Company Telephone",
        "760B82@company_status": "Status",
        "51CC83@company_desc": "Company Introduction (English)",
        "D4ED09@registration_time": "Registration Time",
        "F703D1@company_name_hk": "Company Name(Chinese)",
        "394FBB@company_name_en": "Company Name(English)",
        "B08D37@addr_hk": "Address(Chinese)",
        "EFBD4B@addr_en": "Address(English)",
        "5AEC72@remark_hk": "Remark(Chinese)",
        "EF978E@remark_en": "Remark(English)"
    },
    "backend_shopUser_supplierUser": {
        "E916BD@company_name": "Company Name",
        "CB120A@user_id": "User ID",
        "26E957@username": "Username",
        "062848@nickname": "Nickname",
        "9CD9E0@status": "Status",
        "B3BDE4@isSuper": "Super User",
        "82F60F@last_login_time": "Last Login Time"
    },
    "backend_productClassify": {
        "CFB3A7@classify_id": "Category ID",
        "3CAC83@classify_name": "Category  Name",
        "ED8AF1@status": "Status",
        "B39EE1@classify_photo": "Category Pictures",
        "1CDE5B@classify_name_hk": "Product Category (Chinese)",
        "667CB5@classify_name_en": "Product Category (English)",
        "EB6F82@add_fast": "Quick Add Category",
        "F93B5D@default": "Default top-level menu"
    },
    "backend_emailList": {
        "1892CE@email_type": "Mailbox Type",
        "A062DF@email_title": "Mail Title",
        "D7675B@send_address": "Sending Email Address",
        "28EE7F@receive_address": "Receive Email Adress",
        "C31D79@isReaded": "Is it read",
        "0C2F58@send_time": "Send Email Time",
        "B1D7F4@read_time": "Read Time"
    },
    "supplier_power_grouping": {
        "3FD60B@Rights_group": "Rights Group",
        "C9B87D@grouping_id": "Group ID",
        "4AE8C4@Group_name": "Full/Part Time",
        "A70BF8@super_administrator": "Super Admin",
        "0E5FF1@operation": "Action",
        "25BFF5@super_administrator_notmodify": "Super Admin cannot be edited"
    },
    "supplier_power_childUser": {
        "DE359D@nickname": "Nickname",
        "825849@head_portrait": "Profile Picture",
        "B2778D@super_user": "Super Admin",
        "AADC1A@LastLogin_Time": "Last Login Time",
        "F18109@state": "Status",
        "65D941@Subuser_management": "User Management",
        "D9FF40@Last_login_IP": "Last Login IP"
    },
    "backend_dashboard": {
        "C8866F@order": "Order",
        "BF8D82@unfinished_order": "Outstanding Order",
        "FD5734@restaurant": "Number of Branches"
    },
    "supplier_submenu": {
        "AD3C65@commodity_management": "Product Management",
        "B77F3A@authority_management": "Permissions Control",
        "99DB4A@Setting": "Settings",
        "12FC12@control_panel": "Dashboard"
    },
    "supplier_supplier_produceManage": {
        "B486AA@delete_all_products": "Delete All Product",
        "4E60F8@Management_products": "Product Listing",
        "F12B2D@input_the_key_words": "Input the key words",
        "C84B53@goods_ID": "Product ID",
        "8D36FA@classification_of_goods": "Category",
        "ADD410@Commodity_images": "Image",
        "753635@price": "Price",
        "72E6C1@putaway": "Active",
        "4D7AF5@Quantity_unit": "Unit",
        "B8BD33@product_name": "Product"
    },
    "restaurant_submenu": {
        "E0E5FB@dashboard": "Dashboard",
        "1B2E2F@authrity": "Permissions Control",
        "BA20D2@Privilege_grouping": "Permission Group",
        "896D7E@Sub_user": "User Management",
        "65D701@Operation_record": "Usage Record",
        "2C231F@store_management": "Branch Management",
        "41AF69@Commodity_classification": "Product Categories",
        "FA62B7@supplier": "Supplier",
        "0F2640@supplier_list": "Supplier List",
        "8F6D4D@supplier_products": "Supplier Products",
        "BFB6EB@commodity_attr": "Product Specification",
        "D74010@procurement": "Procurement",
        "396A54@general_order": "Order Management",
        "D6B9A2@Optional_products": "Create Order",
        "2B68B6@b2b_product": "B2B Product",
        "E6A81C@Inventory": "Inventory",
        "C58F2C@accounting": "Accounting",
        "59F88B@Integral_record": "Integral Record",
        "8F506E@company_info": "Company Infomation",
        "446088@setting": "Settings",
        "5409DA@add_supplier": "Edit Supplier",
        "6B6530@add_supplier_editSupProduct": "Edit Supplier Product",
        "90D6D1@order_det": "Order Detail"
    },
    "supplier_supplier_goodsAttribute": {
        "49D281@commodity_property": "Product Specification",
        "E416AD@attribute_name": "Product Specification",
        "D0456F@attribute_value": "Type",
        "3FB998@Attribute_name_hk": "Specification Name(Chinese)",
        "809D25@Attribute_name_en": "Specification Name(English)"
    },
    "restaurant_supplier_supplierList": {
        "8C238E@name": "Name",
        "E318B6@desc": "Description",
        "E84BAF@address": "Address",
        "43B05F@email": "Email",
        "28AF0E@phone": "Phone",
        "0666FD@pay_type": "Payment Terms",
        "5CA70A@min_order": "Minimum Order Amount",
        "8FC08B@reduction_conditions": "Free Delivery Amount",
        "2E31F9@fee": "Delivery Fee",
        "4A7048@supplier_id": "Supplier ID"
    },
    "supplier_partner": {
        "34C475@name": "Name",
        "249A92@payment_method": "Payment Terms",
        "AADE98@Late_payment_day": "Credit Term",
        "A73300@Designated_monthly_statement_day": "Monthly Settlement Date",
        "475422@partner": "Restaurant Partners",
        "C6DED8@partner_ID": "Partner ID"
    },
    "restaurant_supplier_products": {
        "3B1C04@product_id": "Product ID",
        "DC5311@Product_classification": "Category",
        "CE8240@product_name": "Product",
        "7E39D8@img": "Image",
        "C04851@price": "Price",
        "FEF412@supplier_name": "Supplier",
        "B3EF6A@status": "Active",
        "5398AC@unit_number": "Unit Number",
        "CDC4F4@unit": "Unit"
    },
    "restaurant_store": {
        "6D46FE@store_id": "Branch ID",
        "1DC508@contact": "Contact Person",
        "938F71@HongKong_SAR": "District",
        "E99B03@name": "Branch",
        "763B74@address": "Branch Address",
        "0C4048@phone": "Contact Number",
        "BA05EA@store_name_hk": "Branch(Chinese)",
        "238DE5@store_name_en": "Branch(English)"
    },
    "supplier_order": {
        "E0C2CB@order_management": "Order Management",
        "E5F50A@order_status": "Order Status",
        "46C89C@order_reference": "Order No.",
        "CB13F3@type": "Type",
        "066E3C@shop": "Branch"
    },
    "supplier_company_information": {
        "A5E131@whatsapp_group":"WhatsApp Group",
        "65F497@company_description": "Company Introduction",
        "773745@contact_number": "Contact Number",
        "65F49B@company_description": "Company Introduction (Chinese)",
        "9B4616@company_address": "Company Address (Chinese)",
        "539097@company_information": "Company Information"
    },
    "supplier_TransactionSettings": {
        "CD4A38@delivery_fee": "Free Delivery Amount",
        "2435B2@Transaction_settings": "Transaction Settings"
    },
    "restaurant_supplier_attr": {
        "14816B@attr_id": "Specification ID",
        "F17085@attr_name": "Specification",
        "72DFF1@attr_val": "Type",
        "D76F17@sort": "Sort",
        "CF4A03@attr_name_hk": "Specification Name(Chinese)",
        "50B6CC@attr_name_en": "Specification Name(English)",
        "DBE743@more_val": "Please enter a property value, multiple property values please use English semicolon separation",
        "B6A194@default": "Default Optional"
    },
    "restaurant_buy_genner": {
        "30D594@order_id": "Order No.",
        "FE6497@store": "Branch",
        "7B0C54@type": "Type",
        "586E4F@supplier_name": "Supplier Name",
        "E0A3B6@order_price": "Total Order Amount",
        "759F57@pay_status": "Payment Status",
        "17E296@order_status": "Order Status",
        "97F49A@created_order": "Create Purchase Order",
        "99D144@type": "Choose Type",
        "13B04D@Optional_supplier": "Optional Supplier",
        "59739F@choose_supplier": "Choose Supplier",
        "4C75AE@tips": "Note: Supplier cannot be changed after pressing Create",
        "452B30@created": "Create",
        "E3B214@place_order_user": "User",
        "47DC09@created_general": "Purchase Order",
        "557AE0@select_store": "Select Branch",
        "D6582C@add_goods": "Add Goods",
        "D78E87@place_order": "Place Order"
    },
    "restaurant_buy_optionalOrder": {
        "283B63@product_id": "Product ID",
        "FFD749@product_img": "Image",
        "7AB313@classify": "Category",
        "64F0A6@product_name": "Product",
        "1C9E17@supplier_name": "Supplier",
        "9D943A@price": "Price",
        "118294@shopcart": "Add to Shopping List",
        "F1FD94@Choose_specifications": "Choose Specifications",
        "2744C9@number": "Quantity",
        "C5B93F@color": "Color",
        "861FA0@number_unit": "Unit/Number"
    },
    "restaurant_buy_B2BProduct": {
        "B3EB47@product_id": "B2B Product ID",
        "6C52C8@product_name": "Product",
        "DCD279@sku_id": "B2B Product Specification ID",
        "8BF399@sku_name": "Product Specification",
        "EF1083@unit_price": "Price",
        "52D795@supplier_name": "Supplier Name",
        "2D3DAB@buy_time": "Buy Time"
    },
    "restaurant_inventory": {
        "227D37@inventory_id": "Inventory ID",
        "CF7A68@store": "Branch",
        "0009CF@product_name": "Product",
        "198560@sku_name": "Product Specification",
        "E5BE78@quantity_purchased": "Quantity of Goods Purchased",
        "812240@buy_quantity": "Purchase Unit",
        "05A3D5@calculate_quantity": "Conversion Rate",
        "98D94E@Inventory_quantity": "Inventory",
        "307A4B@Inventory_unit": "Inventory Unit",
        "8E8BAB@calculate_unit": "Inventory Unit"
    },
    "restaurant_accouting_record": {
        "22C5BC@record_id": "Record ID",
        "1B48BB@order_code": "Order No.",
        "228DB4@integral_type": "Integral Operation Type",
        "7597E7@integral": "Integral",
        "23DF28@record_time": "Record Time",
        "C59B8B@increase": "Increase",
        "E94CF8@reduce": "Reduce"
    },
    "restaurant_setting_companyInfo": {
        "A88F99@display_name_hk": "Display Name(Chinese)",
        "A77F35@display_name_en": "Display Name(English)",
        "B056EF@email": "Email",
        "D20198@telephone": "Contact Number"
    },
    "restaurant_dashboard": {
        "E3BBED@order_request": "Payables",
        "27D6A5@unfinish": "Outstanding Orders",
        "11D38A@store": "Suppliers"
    },
    "supplier-setting-DistributionSet": {
        "0823DB@DistributionSet": "Distribution Settings",
        "6198C8@delivery_date": "Delivery Date",
        "EB504B@Distribution_area": "Distribution Area",
        "F1E87C@Select_date": "Select Date",
        "1DB5AF@Select_region": "Select Region",
        "F988E1@Select_region": "Select Region"
    },
    "restaurant_supplier_addsuppluer": {
        "6BA9D8@name_hk": "Supplier (Chinese)",
        "4A6809@name_en": "Supplier (English)",
        "0C63A5@linkman": "Contact Person",
        "AB65B0@cash_delivery": "C.O.D",
        "2FDEF9@Deferred_payment": "Credit Term",
        "4AF4CD@specify_date": "Monthly Settlement",
        "BF08D0@overdue_days": "Credit Term",
        "610635@specify_date": "Monthly Settlement"
    },
    "restaurant_supplier_addSupProduct": {
        "BAA03D@basic_tab": "Basic",
        "BF0A77@product_attr": "Product Specification",
        "AF8EC7@name_hk": "Product Name(Chinese)",
        "EA9E65@name_en": "Product Name(English)",
        "E9513A@product_name": "Product Name",
        "015CB0@key": "Keywords",
        "183798@space": "Distinguish keywords by Spaces",
        "DC9557@sort": "Sort",
        "3E1559@clear_attr": "Clear Attribute",
        "43EF6B@attr_name": "Attribute Name",
        "16EBEC@price": "Price",
        "5A7BCB@number_unit": "unit number / Commodity unit",
        "AAB37F@no_data": "No Data"
    },
    "shop_cart": {
        "23490B@shopcart": "Shopping List",
        "E65DF9@goods_img": "Image",
        "4C8583@name": "Name",
        "86F6A0@unit_price": "Price",
        "D3B150@number": "Quantity",
        "CB55C1@specifications": "Product Specification",
        "936345@total_price": "Total Amount",
        "883AA6@send_date": "Availability",
        "7E9DE5@check_all": "Select All",
        "C0D4AB@choosed": "Selected Products",
        "A91ABF@order_price": "Order Amount",
        "1ED05B@rate": "Free Delivery Amount",
        "1AE43D@total": "Total",
        "C83C45@settlement": "Settlement"
    },
    "supplier-produceManage-publishProduct": {
        "2072FB@classify_id": "Category ID",
        "BD6C55@Name_hk": "Name(Chinese)",
        "36F75F@name_en": "Name(English)",
        "3B83F2@keyword": "Keywords",
        "226509@Distinguish_keywords_by_Spaces": "Distinguish keywords by Spaces",
        "785991@Introduction_en": "Description(English)",
        "14719A@Introduction_hk": "Description(Chinese)",
        "92C10D@Shelf": "Active",
        "DBCC4F@Number_of_Units": "Number of Units",
        "4E6A29@Commodity_unit": "Unit",
        "97DB98@Minimum_purchase_quantity": "Minimum Order Quantity",
        "EE6D61@Sort": "Sort",
        "E400F7@Product_Image": "Product Image",
        "B7148B@Basic_Settings": "Basic Settings",
        "98875D@Product_specifications": "Specification",
        "E9BF3B@Number_of_Units_Commodity_unit": "Number of Units/Commodity unit",
        "D4430E@New_specifications": "New Specification",
        "799C97@Clear_specification": "Clear Specification"
    },
    "orderDetail": {
        "C0ED69@print": "Print",
        "F854E2@be_processed": "Awaiting Confirmation",
        "87CE30@In_processing": "Processing",
        "7879F8@have_received": "Received",
        "A119AD@has_payment": "Paid",
        "35ED62@order_closed": "Order Closed",
        "AB7C91@invoice_number": "Invoice No.",
        "845D3E@buyer": "Buyers",
        "082549@order_time": "Order Created",
        "2D64A4@store_name": "Branch",
        "2775BD@store_tele": "Phone",
        "6F5A1A@delivery_adderss": "Address",
        "30DB36@Payment_status": "Payment Status",
        "FF66B0@supplier": "Supplier",
        "7FC644@supplier_tele": "Contact Number",
        "F6B332@Supplier_contact": "Contact Person",
        "7CA68E@total_price": "Total Amount",
        "CAF2C7@number": "Purchase Quantity",
        "3BB7C2@Accept_number": "Receive Quantity",
        "04B67B@Credits_deducted": "ChefPoints Used",
        "98FF8B@next": "Back",
        "EB17E9@Refuse_order": "Refuse Order",
        "73FE8F@Cancel_order": "Cancel Order",
        "7917E0@Accepted_quantity": "Accepted Quantity"
    },
    "shop-index": {
        "E96A88@index": "Home",
        "9FD647@store": "Features",
        "ADD4F1@Please_enter_key_words": "Please enter key words",
        "6449D6@product_category": "Category",
        "C8B82E@company": "Company",
        "BA8EF4@about_us": "About Us",
        "6D3E8C@Privacy_Policy": "Privacy Policy",
        "F113A5@contac_us": "Contact Us",
        "5D4405@Terms_and_conditions": "Terms and Conditions",
        "3B0294@contact": "Contact",
        "12AD58@phone": "Phone",
        "D32B7A@time": "Time",
        "D72298@Monday_to_Friday": "Monday to Friday",
        "EEA554@Saturday_to_Sunday": "Saturday to Sunday",
        "382FFB@confirm_order": "Confirm Order",
        "1235FF@modify_order": "Modify Order",
        "EACECB@Return_to_the_mall": "Return to the mall"
    },
    "shop-goods": {
        "BED8D2@goods": "Marketplace",
        "88E57F@Minimum_order_amount": "Minimum Order Amount",
        "E6DC79@Full_reduction": "Free Delivery Amount",
        "A29F44@search": "Search",
        "D4F96B@kind": "Category",
        "5C23E3@Switch_language": "Switch Language",
        "740AD3@login": "Login",
        "486EA7@More": "More",
        "CFD43B@No_specifications": "No specifications",
        "D33083@Remaining_points": "ChefPoints Balance",
        "5F5919@Minimum_order_quantity_ratio": "Minimum Order Quantity Ratio",
        "EA3800@supplier_LOGO": "Supplier LOGO",
        "731456@Supplier_profile": "Supplier Description",
        "1D4230@Fill_in_the_form": "Fill in the form",
        "299596@Remarks": "Remark",
        "B5507E@submit": "Submit",
        "43461C@Receiving_part": "Receiving part",
        "1988CD@Receiving_completed": "Receiving Completed",
        "3D33DE@Please_tick_the_category": "Please Tick the Category！",
        "1213D7@Please_select_supplier": "Please Select Supplier！",
        "FC4B28@Please_improve_the_product_specification_information": "Please Improve the Product Specification Information!",
        "919A0F@Please_fill_in_the_quantity_of_the_product": "Please Fill in the Quantity of the Product！",
        "8264A9@Add_to_Cart_successful": "Add to Shopping List Successful！",
        "91B7D6@please_log_in_first": "Please Log in First！",
        "8BF492@Unchecked_items_cannot_be_placed": "Unchecked Items Cannot be Placed！",
        "9DD40C@Please_choose_a_store": "Please Choose a Branches！",
        "9A0301@Please_complete_the_account_password": "Please Complete the Account and Password！",
        "C8E0FC@Please_fill_in_the_group_name": "Please Fill in the Group Name！",
        "A16978@Please_select_a_role": "Please Select a Role！",
        "2ECFD0@Confirm_delete": "Confirm Delete？",
        "032EEE@Confirm_reset_password": "Confirm Reset Password?",
        "768789@Confirm_receipt": "Confirm Receive?",
        "ABFDC5@Confirm_the_change": "Confirm  Change?",
        "16E8D3@Successful_operation": "Successful Operation！",
        "A906CE@Network_error_please_try_again_later": "Network Error, Please Try Again Later！",
        "5E8023@Please_complete_the_form": "Please Complete the Form！"
    },
    "restaurant_buy_print": {
        "9C5FBF@supplier_info": "Supplier Infomation",
        "59DDF6@order_info": "Order Infomation",
        "2DC8EA@product_info": "Commodify Infomation",
        "945C82@Print_immediately": "Print Immediately",
        "470F44@Latest_date": "Payment Due",
        "B06A44@Branches_stores": "Branch Stores",
        "2886D5@Order_store_phone": "Order Branch Phone",
        "54AB78@Order_delivery_address": "Order Delivery Address"
    },
    "aboutus": {
        "D5E161@modify_payment_status":"Modify Payment Status",
        "06D38E@online": "B2B ONLINE",
        "9C7F56@food": "FOOD INGREDIENTS",
        "FE6F4C@ingredients": "MARKET PLACE",
        "BE3023@join": "Join Us！",
        "A52D91@solve1": "Difficult to find suitable suppliers - Restaurateurs always need to rely on referrals or limited information on internet",
        "1D4E1A@solve2": "Lack of transparency in prices and quality of food ingredients",
        "E1E269@solve3": "Waste time and labour resources on data entry for each procurement order",
        "458BFD@solve4": "Wrong orders of purchase due to human error",
        "4C7AB7@feature": "FEATURES",
        "23DAB9@feature_title1": "PROCUREMENT MANAGEMENT",
        "6D1C81@feature1": "A systematized way to send purchase order and manage invoices in order to avoid any human error.   No more missing or late orders.",
        "AD5C20@feature_title2": "BACK-END COST ANALYSIS",
        "893305@feature2": "An efficient way of monitoring your daily cost with minimal hassle.  Detailed analysis shown  with just one click.",
        "9ACA2B@feature_title3": "ONLINE FOOD INGREDIENTS MARKETPLACE",
        "7BDA32@feature_title4": "MEMBERSHIP REWARD SYSTEM",
        "CEA6B3@feature3": "For you to find new suppliers, compare prices and directly order through our integrated online marketplace.  We are here to help you find the most suitable ingredients.",
        "B49CB8@feature4": "Earn ChefPoints by purchasing on marketplace, then use your Chefpoints as store credit –  Buy more earn more!",
        "1E0AA1@fee1-1": "Place Order On the Internet",
        "FD8464@fee1-4": "Loyalty Reward Programme",
        "F44CA0@fee2-1": "Procurement Analysis Report",
        "DB85C3@fee2-3": "Product Price and Quantity Variance",
        "C41E8F@fee5": "Purchase Order Management",
        "3A74F7@fee3-3": "Supplier Product Catalogue",
        "36F318@fee7": "Repeat Regular Orders",
        "4D7ADA@fee8": "Issue Tender",
        "C03B4A@partner": "OUR  PARTNERS",
        "DD9192@fee_plan": "Price",
        "907247@contact_us": "CONTACT US！",
        "57B601@info": "Message",
        "23CC4E@name": "Name",
        "E928CD@issue": "Chefstation",
        "C5B9F6@solve": "Solves",
        "72F5FF@Minimum_order_quantity": "M.O.Q",
        "B0A0B1@radio": "Proportion",
        "BE94C4@fee3-4": "Monitor  Payment Status",
        "72AB93@Product_Details": "Product Info",
        "3DB928@Supplier_Details": "Supplier Info",
        "935D28@ChefStation": "THE ISSUE",
        "1446FC@no_fee": "NO FEE",
        "1020E4@no_fee1": "We charge no fee for restaurants",
        "6AD290@Current_Inventory": "Stock Count",
        "CE2CE2@Historical-stock": "Historical Inventory",
        "BAABFA@Confirm_the_modification": "Confirm the Modification?",
        "CCBA90@Confirm_cancellation": "Confirm Cancellation?",
        "098C3F@all_products": "All Products",
        "351BB4@All_suppliers": "Supplier",
        "49CA13@personal": "Personal",
        "9C51BD@E-mail_order": "E-Mail Order",
        "D4D3E7@chat": "Chat",
        "AF9C99@month_order_money": "Daily Sales Amount (Current Month)",
        "CD5BE2@month_money_top10": "Top 10 Products by Sales(Current Month)",
        "405894@month_revice_top10": "Top 10 Products by Quantity(Current Month)",
        "15D285@chart": "Charts",
        "374FC5@month_order_all": "Current Month Orders",
        "A01056@order_change": "Purchase Variance",
        "09AE4A@primary_account": "Primary Account",
        "6A7E47@initial_unit": "Order Price",
        "2DF5D9@pay_unit": "Receive Price",
        "03863E@last_date": "The Latest Payment Date",
        "B0F833@order_time": "Date",
        "0390DF@difference": "Difference",
        "58431A@chatroom": "Chat",
        "9E51D1@Order_item_amount_difference": "Purchase Price Variance",
        "412295@Order_quantity_difference": "Purchase Quantity Variance",
        "C44096@Historical_inventory_ID": "Historical Inventory ID",
        "3482AB@Recording_time": "Date",
        "13C31B@modification_time": "Last Edited",
        "C3BE54@admin": "Admin",
        "662D36@staff": "Staff",
        "CEECB1@is_edit": "Editing Allowed",
        "A94527@spec_id": "Specification ID",
        "A97E8F@Description": "Description",
        "3A6FE5@product_num": "Product Quantity (can edit)",
        "96F4F8@product_price": "Price",
        "CBD374@total_price": "Total Amount",
        "482D27@commodity_attr": "Company Address (Chinese)",
        "9E0005@order_min": "Free Delivery Amount",
        "1886CC@fee": "Delivery Fee",
        "F31309@fee_ways": "Payment Terms",
        "E56154@user_name": "Username",
        "1DFF41@Send_PO": "Send P.O.",
        "87F6C1@Balance": "ChefPoints Balance",
        "E820CB@Estimated_delivery_time": "Availability",
        "CB7991@Wrong_estimated_delivery_time": "Wrong Estimated Delivery Time",
        "B69FB2@order_time": "Order Time",
        "6DFA47@Delivery_status": "Delivery Status",
        "E37C0F@common_problem": "FAQ",
        "DE7FD1@restaurant_contact": "Restaurant Person-In-Charge",
        "37A57E@restaurant_phone": "Restaurant Phone Number",
        "29B8DC@top10_restaurant": "Top 10 Restaurants by Sales",
        "D4D5D3@day": "day(s)",
        "F32A9F@company_addr": "Company Address (English)",
        "8F4A3C@company_logo": "Company Logo",
        "A8C409@Terms_of_use": "Terms of Use",
        "99A454@monday": "Monday",
        "6FC140@Tuesday": "Tuesday",
        "7FE14B@Wednesday": "Wednesday",
        "1C3563@Thursday": "Thursday",
        "AA11D7@Friday": "Friday",
        "78EC72@Saturday": "Saturday",
        "CA70D5@sunday": "Sunday",
        "7F2D75@Cash_on_delivery": "Cash on Delivery",
        "782FFE@Deferred_payment": "Deferred Payment",
        "D0F1D6@Set_statement": "Set date monthly statement",
        "C7977E@Payment_Receieved": "Paid",
        "323AC0@Awaiting_Payment": "Pending Payment",
        "3008EA@Add_to_the_optional_classification": "Add to Category",
        "3AA7E7@top10_category": "The category of the top ten purchase amount of the month",
        "45DF7E@Standard_stock": "Par Level",
        "94396A@standard_value": "Par Level",
        "C77370@Login": "Log in",
        "6A20EF@forget_password": "Forget Password",
        "4E7C2A@Please_add_items_first": "Please add items first",
        "F0A61A@Marketplace": "Online Marketplace",
        "EDC382@fee1-2": "Get Food Samples",
        "870877@fee1-3": "Get a Special Offer",
        "E16045@fee2": "Data Analysis",
        "D808AF@fee2-2": "Inventory Analysis Report",
        "47AA8C@fee3": "Procurement  Management System",
        "4B9FED@fee3-1": "Online Order Management",
        "EE182D@fee3-2": "General Order Management (via WhatsApp or Email)",
        "B4F293@Request_sample": "Request Sample",
        "375CE0@Request_a_special_offer": "Request Special Price",
        "915ECD@General_order": "General Order",
        "0CBCA9@normal": "Normal",
        "E0C2B9@lock": "Lock",
        "980E0D@yes": "Yes",
        "A03514@no": "No",
        "14F845@chefpoints": "ChefPoints",
        "26D960@chefPoints_change": "ChefPoints Change",
        "245620@Overdue": "Overdue",
        "572BF7@Order_Type": "Order Type",
        "50BC07@forgot_pass": "Forget Password？",
        "D453CE@Time_of_receipt": "Receiving Times",
        "57925F@register": "Register",
        "66D050@delivery_time": "Delivery Time",
        "48C14D@supplier_platform": "Supplier Platform",
        "09C1E0@Total_amount_of_goods": "Total Product Amount",
        "E3A644@Order_quantity": "Purchase Quantity",
        "AEB42C@Use_ChefPoints": "Use ChefPoints",
        "E8221A@restaurant_name": "Restaurant Name",
        "BAF614@restaurant_chart1": "Daily Order Amount (Current Month )",
        "3B33BC@restaurant_chart2": "Top 10 Products by Purchase Amount(Current Month)",
        "E701FA@resaurant_chart3": "Top 10 Products by Purchase Quantity(Current Month)",
        "327C00@resaurant_chart4": "Top 10 Categories by Purchase Amount (Current Month)",
        "6B3562@Marketplace_Order": "Marketplace Order",
        "2AD773@Restaurant_Details": "Restaurant Info",
        "41856B@supplier_block1": "Unpaid Orders",
        "871D88@Count_date": "Count Date",
        "C9B86B@daily": "Daily",
        "445AA1@Weekly": "Weekly",
        "B8F622@Opening_times": "Opening Times",
        "C28C46@delivery_time": "Delivery Time",
        "06BB26@original_password": "Original Password",
        "3F9881@new_password": "New Password",
        "D904D5@repeat_password": "Repeat Password",
        "3F05B9@equit": "Withdraw  Account",
        "01F5E5@login_success": "Login Successfully！",
        "2E2D92@Quantity_of_Order": "Order Quantity",
        "035886@Repeat_Orders": "Recurring Order",
        "606AE7@login_repeated": "Long time no operation, please login again!",
        "9301F0@Quotation_Information": "Quotation Information",
        "7C0CF5@Upload_failed": "Upload failed, image size cannot exceed 6MB！",
        "7C0CF6@Upload_failed": "Upload failed, document size cannot exceed 30MB!",
        "0DBD9D@Failed_to_load": "Failed to load",
        "8DA31F@New_news": "New news",
        "0CE600@Send_a_message": "Send a message",
        "88E89A@Return": "Return",
        "2FB613@Send": "Send",
        "9397BF@Click_OK_to_Send_the_Picture": "Click OK to Send the Picture",
        "9397B8@Click_OK_to_Send_the_Doc": "Click confirm to send out the document",
        "B36F4C@chat_record": "Chat Record",
        "882633@No_Notice": "No Notice",
        "2F9ADA@Mark_as_read": "Mark As Read",
        "3DC252@new_message": "New Message",
        "25DB6F@account_number": "Account Number",
        "273505@Refresh_permissions_manually": "Refresh permissions manually",
        "DF0FCE@In_Stock": "In Stock",
        "18EF78@Book": "Book",
        "A5B775@Quantity_Status": "Quantity Status",
        "D11489@Order_Deadline": "Cut-Off Time",
        "24F080@Bank_Name": "Bank",
        "1A4DE1@Account_Name": "Payee",
        "C232EA@Account_Number": "Account No.",
        "2F1443@Bank_Info": "Bank Info",
        "F98ACA@Constant": "Constant",
        "BCC0D3@my_favourite": "Favourites",
        "D38FDE@Inoperable": "Inoperable",
        "4E9CB1@Order_Combination": "Order Combination",
        "BBBC82@Group_ID": "Full/Part Time",
        "06951D@Group_Name": "Group Name",
        "0119CF@Number_of_Product_Groups": "Products in Combination",
        "A7A890@Produc_ID": "Product ID",
        "900298@Please_Enter_a_Product_Name_or_Keyword": "Please Enter a Product Name or Keyword",
        "742833@There_are_No_Products_in_This_Combination_Cannot_be_Added_to_Cart": "There are No Products in This Combination, Cannot be Added to Cart",
        "5454F8@Marketplace_Product": "Marketplace Product",
        "D278B5@please_login_first": "Failed to access the page, please login first!",
        "6505D3@Plan_Subscription": "Subscription Plan",
        "ED5B08@Plan_Name": "Plan Name",
        "B4FA87@Number_of_planned_stores": "Number_of_planned_stores",
        "C4970F@Basic_Cost": "Basic Cost",
        "A32813@Exceeding_Planned_Store_Costs": "Exceeding Planned Store Costs",
        "8F1D59@Shelves": "Shelves",
        "7FF405@Plan_Name_English": "Plan Name(English)",
        "1F998B@Plan_Name_Chinese": "Plan Name(Chinese)",
        "2F8186@Upload_failed": "Upload failed, image size cannot exceed 5MB!",
        "23D2D3@Upload_failed": "Upload failed, pictures can only be uploaded in jpg format!",
        "8F2EAB@slide_photo": "Banner",
        "1C3EE0@log": "Log",
        "C0DD27@regular_order": "Regular Order",
        "47CFC2@timerId": "Timer Id",
        "88B1A4@timer_name": "Timer Name",
        "33EAEC@date_type": "Repeat Order Type",
        "E0F102@range": "Set  time",
        "225A90@created_time": "Create Time",
        "6B52ED@monthly": "Month",
        "A85781@result": "Execution Result",
        "DCF209@execution_time": "Set time",
        "CAF889@day": "Day",
        "C7C495@info": "Infomation",
        "075530@massUp": "Enable/Disable",
        "B76508@showProduct": "Shelv",
        "02B038@hidePRoduct": "Removal",
        "981221@ecex_time": "Execution time",
        "0B8401@set_user": "Set User",
        "21AFC6@Product_No": "Product No",
        "CEA76C@Product_unit": "Product Unit",
        "47C789@Product_Unit_Name": "Product Unit Name(English)",
        "840BD3@Product_Unit_Name": "Product Unit Name(Chinese)",
        "C9B5FF@Product_Unit_ID": "Product Unit ID",
        "C676AA@The_operation_will": "The operation will modify the unit information of all products. Are you sure to modify it?",
        "FFCC64@Are_you_sure_to_add": "Are you sure to add?",
        "8FD945@please_log_in_again": "User information is missing, please log in again！",
        "CAAF92@Download_bulk_edit_Excel": "Download Mass Edit Product Excel",
        "DBF560@Upload_Bulk_edit_Excel": "Upload Mass Edit",
        "033E98@Please_Select_Restaurant": "Please Select Restaurant",
        "5A698D@rights_reserved": "© Celestial Stars Strategy Limited. All rights reserved",
        "F2C0FA@Partner_Product_ID": "Partner Product ID",
        "111BDD@Original_Price": "Original Price",
        "932932@special_price": "Special Price",
        "664E7E@Invalid": "Invalid",
        "0B6945@Cooperation_Products": "Cooperation Products",
        "3AEBA8@My_Supplier": "My Supplier",
        "E9C731@ChefStation_Supplier": "The Market",
        "0467CD@WhatsApp_Order": "WhatsApp Order",
        "66A5FF@Order_Notification_Type": "Order Notification Type",
        "123991@Order_Type_First": "Please Select An Order Type First",
        "0FCD62@Inventory": "Inventory (b.f.)",
        "CC731D@Orders": "Purchases",
        "71DE08@Sales": "Sales",
        "512DA9@Write_Off": "Wastage",
        "3F0589@Theoretical_Inventory": "Theoretical Inventory",
        "DDC645@Actual_Inventory": "Inventory (c.f.)",
        "62183E@Variance": "Variance",
        "EDBC7C@Wastage_Summary": "Wastage Summary",
        "44863D@Report": "Report",
        "6AB704@Write_Off": "Write Off",
        "4849F7@Stock_date": "Stock Date",
        "C9ECA3@Issue_PO": "Create P.O.",
        "1B8D6E@Order_by_Product": "Order by Product",
        "736349@Branch_First": "Please Select A Branch First",
        "144975@Accounting_Entry": "Accounting Entry",
        "9B9285@Bill_Classification": "Bill Classification",
        "AFA01A@sub-categories": "Whether to Allow sub-categories",
        "3C1FAD@Revenue": "Revenue",
        "B4B0FE@Total_revenue": "Total Revenue",
        "C70D90@Cost_of_Sales": "Cost of Sales",
        "733F4F@Total_Cost_of_Sales": "Total Cost of Sales",
        "F16C06@Gross_Profit": "Gross Profit",
        "21F1F5@GP_Margin": "GP Margin",
        "5C09FA@Expenses": "Expenses",
        "29AA04@Total_Expenses": "Total Expenses",
        "183E01@Profit_Before_Tax": "Profit Before Tax",
        "ADC948@Profit_Margin": "Before Tax Profit Magin",
        "DE6DD7@Add_Record": "Add Record",
        "7CD35D@New_Category": "Edit Category",
        "B9DAAB@Export_Bill": "Income Statement",
        "381E69@Date": "Date",
        "FE0DEB@Amount": "Amount",
        "D2772D@Cannot_be_Modified": "Cannot be Edited",
        "60E2D6@On_Timing": "On Timing",
        "A9AC5D@Please_Select": "Please Select a Time",
        "A446F9@Product_changes": "Purchase Variance",
        "EEC3D3@Substandard_Inventory_Products": "Par Level",
        "95A642@Products_In_Total_Amount": "By Purchase Value",
        "C991DB@Receive_Top_Ten_Products": "By Purchase Quantity",
        "0026F6@Purchase_Amount": "By Product Category",
        "62F376@Top_Ten_Vendors_Purchased": "By Supplier",
        "6B0C8F@Loss_Percentage": "Wastage",
        "59395F@Inventory_value": "Inventory Amount",
        "5E837C@Export_report": "Export Report",
        "2AECD0@Export_Order": "Export to Excel",
        "5F8C9F@Purchasing_Price": "Purchase Amount",
        "DD54D2@Product_Chart": "Purchase Analysis",
        "12AAC8@profit": "Gross Profit",
        "F12209@analysis": "Analysis",
        "7EF062@Default": "Default",
        "AB78A8@Day": "Day",
        "420F01@month": "Month",
        "480440@season": "Season",
        "B52CFC@year": "Year",
        "14C616@the_first_season": "First Quarter",
        "5AD174@Second_quarter": "Second Quarter",
        "49F8FC@Third_Quater": "Third Quater",
        "F0305E@Fourth_quarter": "Fourth Quarter",
        "74A5F4@sum": "Purchase amount restaurant ranking",
        "F5021B@Target_Price": "Target Price",
        "1F9F16@Staff": "Staff",
        "41421F@Staff_Type": "Full/Part Time",
        "8AE12E@Income_breakdown": "Income Breakdown",
        "447E60@Employee_ID": "Employee ID",
        "8620F5@Username": "Username",
        "25FC31@Title": "Title",
        "DD713B@Name": "Name",
        "BAB644@Date_of_Birth": "Date of Birth",
        "B1BDBB@Address": "Address",
        "687DD4@Employee_title": "Employee title",
        "5BAB8D@Full": "Full Time",
        "47D798@part_time": "Part Time",
        "A22EBE@Monthly": "Monthly",
        "357941@hourly_rate": "hourly rate",
        "F8647E@Hire_date": "Hire Date",
        "3F5BBF@Leave_date": "Leave Date",
        "E9690C@Salary_type": "Salary Type",
        "E0CB0A@Salary": "Salary",
        "C56440@Open_area": "Open Area",
        "4D4C0B@shut_down": "Shut Down",
        "B58C96@Place_an_order": "Repeat Order",
        "5429E6@Supplier_Type": "Supplier Type",
        "71E150@Search_combination_name": "Search Combination Name",
        "578A62@Search_Supplier_Name": "Search Supplier Name",
        "F9CC44@Total_amount_of_goods": "Total amount of goods of Goods",
        "22B84B@reference_number": "Reference No.",
        "AB134B@Payee": "Payee",
        "C97519@supplier_no": "Supplier ID",
        "5C6796@Food_cost": "Food Cost",
        "7D8E82@Schedule": "Weekly Schedule",
        "4712AC@Overtime_pay": "Overtime Pay (Hourly)",
        "76605B@hour": "hour",
        "BADE55@Job_Type": "Work Type",
        "1C32A1@Work": "Work",
        "FC1FB9@Official_Holiday": "Day Off",
        "8DAD0F@sick_leave": "Sick Leave",
        "CC1840@Annual_Leave": "Annual Leave",
        "FFECA8@Total_arrangement_hours": "Work Hours (Current Month)",
        "327289@Overtime": "Overtime",
        "48E7FC@cost": "Cost of Sales",
        "476677@Employee_working_hours_per_month": "Employee Working Hours Per Month",
        "832B7C@Not_due": "Not Due",
        "7B072D@Overdue": "Overdue",
        "ADE56E@Employee_salary": "Payroll",
        "EE7271@Select_month": "Select Month",
        "2A0D3F@Working_hours": "Working Hours",
        "E96BBF@Normal_time_salary": "Normal Time Salary",
        "3F765C@Overtime_pay": "Overtime Pay",
        "D7E9FD@Additional_charges": "Additional Adjustment",
        "84242F@Total_salary": "Total Salary",
        "C70759@Employee_MPF": "Employee MPF",
        "A20A57@Employer_MPF": "Employer MPF",
        "467CD2@one_fix": "Modify",
        "BF70BE@Break_time": "Break Time",
        "A9A7F4@Open_the_schedule": "Open The Edit Table",
        "CF6BE6@mon": "/Month",
        "C7FD6E@hours": "/Hour",
        "31B467@Arrange_time": "Arrange Time",
        "696FE4@Start": "Start",
        "44C071@End": "End",
        "E82D58@Pleas_selected_type": "Please Fill In the Time Of the Selected Type",
        "A627ED@Arrangement_type": "Arrangement Type",
        "01D9C2@batch_modify": "Batch Modify",
        "A6801E@Change_time": "Edit Table",
        "BA6AE5@Unpaid_order_amount": "Unpaid Amount",
        "2CF1A8@Total_Purchase_Amount": "Purchase Amount",
        "7BE40B@Top_five_purchases": "Top Five Purchases",
        "6F41DA@Accept_top_five_products": "Accept Top Five Products",
        "24F369@Top_5_categories_of_purchase_amount": "Top 5 Categories of Purchase Amount",
        "C16F26@Top_5_suppliers_with_total_purchase_amount": "Top 5 Suppliers With Total purchase Amount",
        "BDBC1F@Loss": "Wastage",
        "8B9C12@no_receive": "Orders to be Received",
        "355446@Monthly_salary_hourly_salary": "Monthly salary / hourly salary",
        "2EFE79@30_profit": "Last 30 days profit",
        "8CBEFD@my_restaurant": "My Restaurant",
        "B60983@topfive_30": "Top 5 by Purchase Amount (Past 30 Days)",
        "F89D2B@top5_30": "Top 5 by Purchase Quantity (Past 30 Days)",
        "E558D5@top5_30": "Top 5 by Product Category (Past 30 Days)",
        "9703BB@top5_30": "Top 5 by Supplier (Past 30 Days)",
        "C32928@edit": "Edit Permission",
        "9E728C@salary": "Salary (Monthly/Hourly)",
        "AB0929@side_bar": "Product Category",
        "119D08@side_bar": "Staff Type",
        "F3F8D5@sup_addr": "Supplier Address (Chinese)",
        "CB7169@sup_addr": "Supplier Address (English)",
        "D1BE99@bank2": "Bank 2",
        "9BE18D@bank2": "Payee 2",
        "F75A75@bank2": "Account No. 2",
        "B003A6@This_is_required": "This is required",
        "B7722D@Supplier_Address": "Supplier Address",
        "27721E@type": "Order Type",
        "0AB255@Inventory": "Inventory",
        "80E0A4@branch_addr": "Branch Address (English)",
        "CF24C8@branch_addr": "Branch Address (Chinese)",
        "6A5B3D@Daily_break": "Daily Break Time (Hours)",
        "59C826@time": "Time Created",
        "4AF32C@repeat_time": "Repeat Order Timer",
        "DC552B@Awaiting": "Pending Confirmation",
        "7A52EA@order_date": "Order Date",
        "95BD63@receive_date": "Receive Date",
        "3697EB@Blog": "Blog",
        "E7F9ED@Blog_type": "Blog Type",
        "B99A84@Blog_list": "Blog List",
        "0176C5@Author": "Author",
        "07C2A1@Whether_to_publish": "Whether to publish",
        "A57A84@grade": "grade",
        "43A2F7@Level_one": "Level one",
        "EE1777@Level_2": "Level 2",
        "F86F61@Level_3": "Level 3",
        "259FAD@blog": "Blog",
        "D1060F@read_more": "Read More",
        "E2B943@blog1": "Enjoy Taiwanese food together",
        "C20495@blog2": "Gather all the local cuisine and favorite restaurants for you",
        "BCCCBD@Content_Chinese": "Content (Chinese)",
        "D6AC22@Content_English": "Content (English)"
    }
}

