/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-29 11:29:45
 * @LastEditors: Please set LastEditors
 */
import Vue from 'vue';
import Router from 'vue-router';

import { Message } from 'element-ui';
import { getlocalStorage } from './utils/localStorage'


import Layout from '@/components/Layout/Layout';
const Login = () =>
  import( /*webpackChunkName:'Login'*/ '@/pages/Login/Login')
const error = () =>
  import( /*webpackChunkName:'error'*/ '@/pages/error/error')
const kong = () =>
  import( /*webpackChunkName:'kong'*/ '@/pages/error/kong')

const Dashboard = () =>
  import( /*webpackChunkName:'Dashboard'*/ '@/pages/Dashboard/Dashboard')

const grouping = () =>
  import( /*webpackChunkName:'grouping'*/ '@/pages/Power/grouping')
const childUser = () =>
  import( /*webpackChunkName:'childUser'*/ '@/pages/Power/childUser')
const manageLog = () =>
  import( /*webpackChunkName:'manageLog'*/ '@/pages/Power/manageLog')


const Anlyse = () =>
  import( /*webpackChunkName:'Anlyse'*/ '@/pages/Report/anlyse')
// const Report = () =>
//     import( /*webpackChunkName:'Report'*/ '@/pages/Report/Report')
const WastageSummary = () => import(/*webpackChunkName:'WastageSummary'*/'@/pages/Report/WastageSummary/WastageSummary')
const ExportReport = () => import(/*webpackChunkName:'ExportReport'*/'@/pages/Report/WastageSummary/ExportReport')

const Order = () =>
  import( /*webpackChunkName:'Order'*/ '@/pages/Order/Order')
const OrderDetail = () =>
  import( /*webpackChunkName:'OrderDetail'*/ '@/pages/Order/orderDetail')
const print = () =>
  import( /*webpackChunkName:'print'*/ '@/pages/Order/print')

const produceManage = () =>
  import( /*webpackChunkName:'produceManage'*/ '@/pages/Supplier/produceManage')
const publishProduct = () =>
  import( /*webpackChunkName:'publishProduct'*/ '@/pages/Supplier/publishProduct')
const goodsAttribute = () =>
  import( /*webpackChunkName:'goodsAttribute'*/ '@/pages/Supplier/goodsAttribute')

const restaurant = () =>
  import( /*webpackChunkName:'restaurant'*/ '@/pages/restaurant/restaurant')

const chatroom = () =>
  import( /*webpackChunkName:'chatroom'*/ '@/pages/chat/chatroom')

const Favorites = () =>
  import( /*webpackChunkName:'Favorites'*/ '@/pages/Favorites/Favorites')
const Fee = () =>
  import( /*webpackChunkName:'Fee'*/ '@/pages/Fee/Fee')
const Accounting = () =>
  import( /*webpackChunkName:'Accounting'*/ '@/pages/Accounting/Accounting')

const Company = () =>
  import( /*webpackChunkName:'Company'*/ '@/pages/Setting/Company')
const DistributionSet = () =>
  import( /*webpackChunkName:'DistributionSet'*/ '@/pages/Setting/DistributionSet')
const underdrive = () =>
  import( /*webpackChunkName:'underdrive'*/ '@/pages/Setting/underdrive')

//發票
const Invoice = () => import(/*webpackChunkName:'Invoice'*/'@/pages/Invoice/Invoice')
//發票細節
const printInvoice = () => import( /*webpackChunkName:'printInvoice'*/'@/pages/Invoice/PrintInvoice.vue')
// //找食材
// const TenderForum = () => import( /*webpackChunkName:'TenderForum'*/'@/pages/Tender/TenderForum')
// const TenderInfo = () => import( /*webpackChunkName:'TenderInfo'*/'@/pages/Tender/TenderInfo')
//評價管理
const RateManage = () =>import( /*webpackChunkName:'RateManage'*/'@/pages/Order/RateMange')

//銷售排行榜
const salesRankings = ()=>import( /*webpackChunkName:'SalesRankings'*/'@/pages/Supplier/SalesRankings')

Vue.use(Router);
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const router = new Router({
  mode: 'history',
  routes: [
      {
        path: '/printInvoice',
        name: 'printInvoice',
        component: printInvoice,
      },
  {
    path: '/invoice',
    name: 'invoice',
    component: Invoice,
  },
  {
    path: '/print',
    name: 'print',
    component: print,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/error',
    name: 'error',
    component: error,
  },
  {
    path: '/kong',
    name: 'kong',
    component: kong,
  },
  {
    path: '/app',
    name: 'Layout',
    component: Layout,
    alias: '/',
    redirect: '/app/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: Dashboard,
    },
    {
      path: 'power/childUser',
      name: 'childUser',
      component: childUser,
    },
    {
      path: 'power/grouping',
      name: 'grouping',
      component: grouping,
    },
    {
      path: 'power/manageLog',
      name: 'manageLog',
      component: manageLog,
    },
    // {
    //     path: 'report/report',
    //     name: 'report',
    //     component: Report,
    // },
    {
      path: 'report/anlyse',
      name: 'anlyse',
      component: Anlyse,
    },
    {
      path: 'report/WastageSummary',
      name: 'WastageSummary',
      component: WastageSummary,
    },
    {
      path: 'Accounting/ExportReport/:start_time/:end_time/:restaurant_id',
      name: 'ExportReport',
      component: ExportReport,
    },

    {
      path: 'order',
      name: 'order',
      component: Order,
    },
    {
      path: 'orderDetail',
      name: 'orderDetail',
      component: OrderDetail,
    },

    {
      path: 'supplier/produceManage',
      name: 'produceManage',
      component: produceManage,
    },
    {
      path: 'supplier/salesRankings',
      name: 'salesRankings',
      component: salesRankings,
    },
    {
      path: 'supplier/publishProduct',
      name: 'publishProduct',
      component: publishProduct,
    },
    {
      path: 'supplier/goodsAttribute',
      name: 'goodsAttribute',
      component: goodsAttribute,
    },
    {
      path: 'restaurant',
      name: 'restaurant',
      component: restaurant,
    },
    {
      path: 'chat/chatroom',
      name: 'chatroom',
      component: chatroom,
    },

    {
      path: 'favorites',
      name: 'favorites',
      component: Favorites,
    },
    {
      path: 'fee',
      name: 'fee',
      component: Fee,
    },
    {
      path: 'accounting',
      name: 'accounting',
      component: Accounting,
    },
    {
      path: 'setting/company',
      name: 'company',
      component: Company,
    },
    {
      path: 'setting/DistributionSet',
      name: 'DistributionSet',
      component: DistributionSet,
    },
    {
      path: 'setting/underdrive',
      name: 'underdrive',
      component: underdrive,
    },
    {
      path: 'invoice',
      name: 'invoice',
      component: Invoice,
    },
    // {
    //     path:'Tender/Forum',
    //     name:'TenderForum',
    //     component:TenderForum,
    // },
    // {
    //   path:'Tender/TenderInfo',
    //   name:'TenderInfo',
    //   component:TenderInfo,
    // },
    //   {
    //     path:'RateManage',
    //     name:'RateManage',
    //     component:RateManage,
    //   }
    ],
  },
  ],
});

router.beforeEach((to, from, next) => {
  console.log('to==>', to.matched);
  next();

  let route_id = getlocalStorage("supplierroute_id");
  let btn_id = getlocalStorage("supplierbtn_id");
  let tableData = getlocalStorage("suppliertableData");
  let token = getlocalStorage('suppliertoken')
  let auth_id_json = getlocalStorage('supplierAuth_id_json')
  let supplierUserInfo = getlocalStorage('supplierUserInfo')
  if (to.matched.length == 0) {
    next('/error')
  }
  if (token && route_id && btn_id && tableData && auth_id_json && supplierUserInfo) {
    // 如果是访问登录界面，如果用户会话信息存在，代表已登录过，跳转到主页
    if (to.path === '/login') {
      next({ path: '/app/dashboard' })
    } else {
      next()
    }
  } else {
    const arr = ['/login', '/print', '/printInvoice']
    if (arr.indexOf(to.path) > -1) {
      next()
    } else if (to.name == 'dashboard') {
      next('/login')
    } else {
      next('/login')
      Message.warning(getlocalStorage('lang') == 'en' ? 'Failed to access the page, please login first!' : '訪問頁面失敗，請先登錄!')
    }
  }

})
export default router
