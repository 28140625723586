/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-28 18:07:02
 * @LastEditors: Please set LastEditors
 */
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueTouch from 'vue-touch';

import locale from 'element-ui/lib/locale'

import echarts from 'echarts'
Vue.prototype.$echarts = echarts

import store from './store';
import router from './Routes';
import App from './App';
import VueI18n from 'vue-i18n'
// import iView from 'iview';
// import 'iview/dist/styles/iview.css';


import Print from './plugs/print'
Vue.use(Print)


import { getlocalStorage, setlocalStorage, remlocalStorage } from './utils/localStorage'

Vue.prototype.$getlocalStorage = getlocalStorage
Vue.prototype.$setlocalStorage = setlocalStorage
Vue.prototype.$remlocalStorage = remlocalStorage

// 请求
import http from './http/api.js'
Vue.prototype.$http = http

import downLoadFile from './utils/downLoadFile'
Vue.use(downLoadFile);

Vue.prototype.$inputLenght = function (e) {
  e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
}
Vue.prototype.$inputNumber = function (e) {
  e.target.value = e.target.value.match(/^\d*(\.?\d{0})/g)[0] || null;
}

import websocket from './utils/websocket';
Vue.prototype.global = websocket

Vue.prototype.$tableRowClassName = ({ row, rowIndex }) => {
  if (rowIndex % 2 == 0) {
    return 'gray';
  } else {
    return 'white';
  }
  return '';
}


Vue.use(VueTouch);
Vue.use(VueI18n)
Vue.use(BootstrapVue);
Vue.use(ElementUI);
// Vue.use(iView);

import en from './i18n/lang/en.js'
import hk from './i18n/lang/zh_hk.js'

const i18n = new VueI18n({
  locale: Vue.prototype.$getlocalStorage('lang') || "hk",  // 语言标识
  messages: {
    // "cn": require('./i18n/lang/zh_cn.js'),
    "en": en,
    "hk": hk,
  }
})
locale.i18n((key, value) => i18n.t(key, value))


Vue.config.productionTip = false;

new Vue({
  el: '#app',
  store,
  router,
  i18n,
  render: h => h(App),
});


