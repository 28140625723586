<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-16 11:35:22
 * @LastEditTime: 2019-11-29 18:48:27
 * @LastEditors: Please set LastEditors
 -->
<template>
  <b-navbar class="head d-print-none">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class="fa fa-bars fa-lg" />
        </a>
      </b-nav-item>
    </b-nav>

    <b-nav class="ml-auto">
      <!-- 设置 -->
      <!-- <b-nav-item> -->
      <!-- <i class="fa fa-envelope px-2" /> -->
      <!-- </b-nav-item> -->

      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none info" right>
        <template slot="button-content">
          <el-badge :value="chatInform.badge_sum" :hidden="!badgeShow" class="item">
            <i class="fa fa-envelope px-2" />
          </el-badge>
        </template>
        <b-dropdown-item-button
          v-if="Boolean(chatInform.list&&chatInform.list.length)"
          style=" width: 100%;
    height: 50px;"
        ></b-dropdown-item-button>
        <b-dropdown-item-button v-for="(v,i) in chatInform.list" :key="i" @click="goChat(v)">
          <el-badge :value="v.badge" style="width:100%" :hidden="!badgeShow" class="item">
            <div style="font-size:16px;color:#000;font-weight:500;margin-bottom: 3px;">{{v.title}}</div>
            <div style="font-size:14px;margin-bottom: 3px;">{{v.sub_title}}</div>
            <div style="font-size:12px;margin-bottom: 3px;">{{v.message}}</div>
          </el-badge>
          <b-dropdown-divider />
        </b-dropdown-item-button>
        <b-dropdown-item-button
          class="read"
          v-if="Boolean(chatInform.list&&chatInform.list.length)"
          @click="clearAllInform"
        >{{$t('aboutus.2F9ADA@Mark_as_read')}}</b-dropdown-item-button>
        <b-dropdown-item-button v-else @click="clearAllInform">{{$t('aboutus.882633@No_Notice')}}</b-dropdown-item-button>
      </b-nav-item-dropdown>

      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none language">
        <template slot="button-content">
          <img
            v-if="language == 'hk'"
            src="@/assets/hk.png"
            style="width:30px;margin-right:5px;"
            alt
          />
          <img src="@/assets/en.png" v-else style="width:30px;margin-right:5px" alt />
        </template>
        <b-dropdown-item-button @click="tradition">
          <img src="@/assets/hk.png" style="width:25px;margin-right:5px;" alt />
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button @click="english">
          <img src="@/assets/en.png" style="width:25px;margin-right:5px" alt />
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
      <!-- 账户管理 -->
      <b-nav-item-dropdown class="settingsDropdown d-sm-down-none" no-caret right>
        <template slot="button-content">
          <span class="avatar thumb-sm float-left mr-2">
            <img class="rounded-circle" :src="userInfo.avatar" alt="..." />
          </span>
        </template>
        <b-dropdown-item-button @click="quit">
          <i class="fa fa-sign-out">
            <span style="padding-lefty:10px;">
              {{
                $t('aboutus.3F05B9@equit')
              }}
            </span>
          </i>
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button @click="modifyPass">
          <i class="fa fa-pencil">{{ $t('public.2FC133@modify_pass') }}</i>
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions } from "vuex";
import $ from "jquery";

export default {
  name: "Headed",
  data() {
    return {
      search: "",
      chatInform: {},
      badgeShow: "",
      language: ""
    };
  },
  props: {
    userInfo: ""
  },
  watch: {
    chat_inform: {
      handler(newData, oldData) {
        console.log("新通知", newData);
        let flag = true;
        if (newData.push_data.badge_sum > 0) {
          newData.push_data.list.forEach((v, i) => {
            if (v.id == this.$store.state.room_id) {
              flag = false;
            }
          });
          // if (newData.room_id == this.$store.state.room_id) {
          //   this.badgeShow = false;
          // } else {
          //   this.badgeShow = true;
          //   this.chatInform = newData.push_data;
          // }
          if (flag) {
            this.chatInform = newData.push_data;
            this.badgeShow = true;
          }
        } else {
          this.badgeShow = false;
          this.chatInform = newData.push_data;
        }
      },
      deep: true
    },
    routerName(newData, oldData) {
      if (newData == "chatroom") {
        // this.badgeShow = false;
      }
    }
  },
  computed: {
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic
    }),
    ...mapState("websocket", {
      chat_inform: state => state.chat_inform
    }),
    routerName() {
      return this.$route.name;
    }
  },
  methods: {
    quit() {
      this.$http.logout().then(res => {
        localStorage.clear();
        this.$router.push("/login");
      });
    },
    modifyPass() {
      this.$emit("modifyPass", !this.showDialog);
    },
    ...mapActions("layout", [
      "toggleSidebar",
      "switchSidebar",
      "changeSidebarActive"
    ]),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split("/");
        paths.pop();
        this.changeSidebarActive(paths.join("/"));
      }
    },
    tradition() {
      this.$i18n.locale = "hk";
      this.$setlocalStorage("lang", "hk");
      if (Boolean(this.$getlocalStorage("suppliertoken"))) {
        this.$http.userChangeLang();
      }
      this.$router.push({ path: "/kong", query: {} });
      this.global.initWebSocket();
    },
    english() {
      this.$i18n.locale = "en";
      this.$setlocalStorage("lang", "en");
      if (Boolean(this.$getlocalStorage("suppliertoken"))) {
        this.$http.userChangeLang();
      }
      this.$router.push({ path: "/kong", query: {} });
      this.global.initWebSocket();
    },
    scroll(e) {
      this.$el.querySelector(".read").style.top =
        this.$el.querySelector(".info>.dropdown-menu").scrollTop + "px";
    },
    // 获取通知消息
    getInform() {
      this.$http.getInform().then(res => {
        this.chatInform = res.data;
        if (res.data.badge_sum > 0) {
          this.badgeShow = true;
        } else {
          this.badgeShow = false;
        }
        console.log("聊天通知", res);
      });
    },
    //去往聊天室
    goChat(v) {
      if (v.type == "place_order") {
        this.$router.push({
          path: "/app/orderDetail",
          query: { order_no: v.id }
        });
      } else {
        this.$router.push({
          name: "chatroom",
          query: { room_id: v.id }
        });
      }
    },
    // 清除所有聊天通知
    clearAllInform() {
      this.$http.clearAllInform().then(res => {});
    }
  },
  created() {
    this.getInform();
    this.language = this.$getlocalStorage("lang") || "hk";
    if (window.innerWidth > 576) {
      setTimeout(() => {
        const $chatNotification = $("#chat-notification");
        $chatNotification
          .removeClass("hide")
          .addClass("animated fadeIn")
          .one(
            "webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend",
            () => {
              $chatNotification.removeClass("animated fadeIn");
              setTimeout(() => {
                $chatNotification
                  .addClass("animated fadeOut")
                  .one(
                    "webkitAnimationEnd mozAnimationEnd MSAnimationEnd" +
                      " oanimationend animationend",
                    () => {
                      $chatNotification.addClass("hide");
                    }
                  );
              }, 6000);
            }
          );
        $chatNotification
          .siblings("#toggle-chat")
          .append('<i class="chat-notification-sing animated bounceIn"></i>');
      }, 4000);
    }
  },
  mounted() {
    document
      .querySelector(".info>.dropdown-menu")
      .addEventListener("scroll", this.scroll);
  },
  updated() {}
};
</script>

<style src="./Header.scss" lang="scss"  />
