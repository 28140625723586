/*
 * @Author: your name
 * @Date: 2019-11-07 18:38:26
 * @LastEditTime: 2019-11-25 20:22:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \chefstation_restaurant\src\i18n\lang\zh_hk.js
 */
import hkLocale from "element-ui/lib/locale/lang/zh-TW";


export default {
    ...hkLocale,
    "SalesRankings": {
        "title": "銷售排行榜",
        "rankings": "排行",
        "viewMore": "查看更多",
    },
    "hidden_or_show":{
        "FEA51D@show_label":"顯示給哪些餐廳看見",
        "AD5E46@tip":"未選擇任何餐廳時，將會讓所有餐廳都能看見",
    },
    "product_delivery":{
        'F5EA13@product_delivery':"取貨方式",
        'AD58EF@supplier_direct_shipping':"供應商配送",
        'F5E1A3@in_store_pickup':"自行取貨",
    },
    "tender": {
        "AE3454@name": "找食材",
        "D8E646@forum": "論壇",
        "E1232D@NoReply": "尚未回覆",
        "32DE21@Last_reply_time": "最後回覆時間",
        "E3866A@join_reply":"新增回覆",
    },
    "invoice": {
        "BA1FF5@free": "免費",
        "AE5D11@manage_invoice": "管理發票",
        "AE64AD@menu": "發票",
        "F9100D@invoice_date": "發票日期",
        "0DE312@invoice_number": "發票號碼",
        "A886987@gmv": "成交金額",
        "EA5264@cost_ratio": "交易稅",
        "9E1612@invoice_amount": "發票金額",
        "84D521@pay_status": "付款狀態",
        "ED8512@click_to_see_invoice": "查看發票",
        "F5D21D@unpaid": "未付款",
        "A8312E@paid": "已付款"
    },
    "rating":{
        "A6785E@rate_product":"評分",
        "B8D531@rate_title":"請對此次消費進行評分!",
        "FA5648@rate_comment":"請給些評論!",
        "AED585@rating_manage":"評分管理",
        "ED2551@product_reviews":"產品評論",
        "ADE215@top":"最新評論",
        "ED215F@bottom":"最舊評論",
        "D54EA1@sortBy":"排序",
        "AE2121@filterBy":"篩選",
        "BF215E1@All_Reviews":"所有評論",
        "D545F1@Display_Reviews":"已顯示評論",
        "F21565@Not_Display_Reviews":"不顯示評論",
        "A12E51@one_star_only":"僅一顆星",
        "A12E52@two_star_only":"僅二顆星",
        "A12E53@three_star_only":"僅三顆星",
        "A12E54@four_star_only":"僅四顆星",
        "A12E55@five_star_only":"僅五顆星",
        "E2DF56@all":"全部",
        "F54645@filter":"篩選",
        "B25ED5@Approve":"批准",
        "A2575E@Hide":"隱藏",
    },
    "backend_authrity_authrityList": {
        "A7F8C9@backend_tab": "後台",
        "C90CD7@restaurant_tab": "餐廳",
        "85EDE9@supplier_tab": "供應商",
        "B2EBC3@authrity_id": "權限ID",
        "1DB5E9@simplified_Chinese": "簡體",
        "A75174@Traditional_Chinese": "繁體",
        "B25958@english": "英文",
        "D22040@router": "路由",
        "468002@api": "接口",
        "12349D@type": "類型",
        "0310FF@Superior": "上級"
    },
    "backend_authrity_grouping": {
        "31BA13@grouping_id": "組別Id",
        "5EDDBF@grouping_name": "權限組別名稱",
        "4A9FA6@isSuper": "超級管理員",
        "26BE7D@admin": "管理員",
        "28C052@store": "分店",
        "B3402A@edit_name": "請填寫分組名稱",
        "F1B23F@edit_role": "請選擇角色",
        "422561@edit_store": "請選擇分店"
    },
    "backend_authrity_user": {
        "9A4F2F@username": "用戶名",
        "8319AA@nickname": "簡稱",
        "CA6925@avatar": "頭像",
        "29E7EE@last_ip": "上次登入Ip",
        "BAF37C@last_login_time": "上次登入時間",
        "07D89C@status": "狀態",
        "BBA818@grouping_id": "員工類型",
        "948EEB@password": "密碼",
        "B5AC7E@isSuper": "超級用戶"
    },
    "backend_authrity_operationLog": {
        "8CF925@operationUser": "操作用戶",
        "3531D8@Controller": "控制器",
        "F5AD79@methods": "方法",
        "AC377D@operation_info": "操作信息",
        "E3B71C@operation_time": "操作時間"
    },
    "public": {
        "not_remind_again":"不再提示",
        "bank_info":"銀行資訊",
        "del_message":"要刪除這訊息嗎?",
        "FE11E4@add_goods":"新增產品",
        "ADF5E@email_tip":"有多個Email，可以透過「逗號」/「分號」或「換行」將齊分隔開來",
        "AAE565@is_heloneself":"提供自取",
        "E123AEF@add_order": "新增訂單",
        "DC9752@btn_add": "新增",
        "B6D57F@btn_modify": "修改",
        "1FBB5E@btn_delete": "刪除",
        "2AE651@preview": "預覽",
        "2C74E6@btn_delete_more": "批量刪除",
        "711B38@serial_number": "序號",
        "03EA09@operation": "操作",
        "25F6B0@tips": "超級管理員不能修改權限和刪除帳戶",
        "D17BCA@created_time": "日期",
        "E1A718@update_time": "更新時間",
        "564C21@unlock": "解鎖",
        "DBC110@locked": "鎖定",
        "72A46B@reset_pass": "重設密碼",
        "A738BA@update": "更新",
        "B3669C@show": "顯示",
        "8B9314@hide": "隱藏",
        "4ABAB1@loading": "加載中",
        "84FA27@no_pictures": "暫無圖片",
        "549A91@edit": "編輯",
        "3B45A2@confirm": "確認",
        "836FD4@cancel": "取消",
        "D74E99@simplified_chinese": "簡體",
        "51D01B@traditional_chinese": "繁體",
        "154BAB@english": "英文",
        "A82133@username": "用戶名",
        "BB249F@supplier_name": "供應商",
        "BFFB0F@creation_time": "日期",
        "97656C@Download_the_product_excel_template": "下載產品Excel模板",
        "6796E1@Download_product_excel": "下載產品Excel",
        "37249B@pload_supplier_products": "批量上傳供應商產品",
        "507DF9@new_attribute": "新增屬性",
        "C82510@order_amount": "訂單金額",
        "180B5F@Payment_status": "支付狀態",
        "83746D@download_excel": "下載模板",
        "32355B@download_excel": "導出 Excel",
        "8D75D5@Hong_Kong_Administrative_Region": "區分",
        "93EC9B@upload": "上傳Excel",
        "417BDC@E-mail": "電郵",
        "821DEE@E-mail_order":"訂單通知電郵",
        "A98F12@E-mail_message":"新訊息通知電郵",
        "6EF123@E-mail_invoice":"發票通知電郵",
        "2E733D@Cash_on_delivery": "貨到付款",
        "5D8689@Delayable_payment": "信貸期限",
        "CC56D2@Designated_monthly_date": "每月結算",
        "653358@view": "查看",
        "B8C509@Order_user": "下單用戶",
        "427B43@Minimum_amount": "最低起送金額",
        "6D13C6@delete_tip": "確認刪除嗎？",
        "E91E1D@addr_hk": "地址(中文)",
        "966929@addr_en": "地址(英文)",
        "4BF093@desc_hk": "描述(中文)",
        "90B2DC@desc_en": "描述(英文)",
        "1A3EBC@save": "保存",
        "553159@tips": "提示",
        "B6E56E@no_attr": "無規格",
        "707A1E@logout": "登出",
        "2FC133@modify_pass": "修改密碼",
        "5AD435@Total": "應付總額（含運費）",
        "AE2731@select_product": "請選擇產品",
        "733AA9@Network": "網絡錯誤請稍後重試！",
        "4F36DD@Operation_successful": "操作成功！",
        "4A5563@min_number": "最少訂購數量",
        "64203E@to_be_determined": "待確認",
        "471669@Confirmed_order": "已確認",
        "8E9DAD@Some_accept": "部分接收",
        "4E63B1@unread": "未讀"
    },
    "placeholder": {
        "524620@start_time": "開始時間",
        "ABB7CF@end_time": "結束時間",
        "C209ED@select": "請選擇",
        "9A92DD@username": "用戶名",
        "F0DDC0@select": "請選擇",
        "3F6A37@supplier": "選擇供應商",
        "D4E968@order_status": "訂單狀態",
        "5C894A@order_code": "訂單編號",
        "7C1BF7@search_key": "搜索產品或關鍵字",
        "F8EA00@select_type": "選擇分類",
        "D20BE9@Please_input": "請輸入"
    },
    "backend_submenu": {
        "ABAAF2@dashboard": "控制中心",
        "ED96C7@authrity": "權限管理",
        "4A302E@authrityList": "前端權限列表",
        "21BF37@grouping": "權限組別",
        "61A3ED@user": "用戶",
        "6A0B0E@Operation_record": "操作記錄",
        "0FED78@config": "配置項",
        "0265E5@Advertisement": "廣告",
        "157A9D@platform_Info": "平台信息",
        "4F09AA@index": "首頁分類",
        "49DFF3@integral": "積分",
        "AD8E7D@Merchant_man": "商家管理",
        "411879@restaurant_list": "餐廳列表",
        "BE2794@supplier_list": "供應商列表",
        "100AE5@Merchant_user_management": "商家用戶管理",
        "526167@restaurant_user_man": "餐廳用戶管理",
        "92671D@supplier_user_man": "供應商用戶管理",
        "96B8E2@Product_classification": "分類",
        "A15264@order_manage": "訂單管理",
        "EF42B5@email_list": "郵件列表"
    },
    "backend_config_Advertisement": {
        "0672CC@ad_id": "廣告Id",
        "D34399@ad_type": "廣告類型",
        "88A5B8@ad_title": "標題",
        "88A6D5@ad_url": "跳轉鏈接",
        "46F8F7@isShow": "是否開放",
        "1E4172@sort": "排序",
        "8A317E@photo": "廣告圖",
        "23FE59@title_en": "標題(英文)",
        "005381@title_hk": "標題(中文)",
        "EA667C@top_banner": "首頁頂部輪播圖",
        "136CD4@bottom_banner": "首頁底部輪播圖",
        "778D25@med_banner": "首頁中間4格廣告"
    },
    "backend_config_platformInfo": {
        "837359@email": "電子郵箱",
        "936621@phone": "聯絡電話",
        "607689@Copyright": "版權",
        "D6C85F@bottomLogo": "首頁底部Logo"
    },
    "backend_config_home": {
        "1DC9DD@classify_id": "分類ID",
        "9266EE@classify_photo": "分類圖片",
        "47380E@sort": "排序",
        "966A47@classify_name": "分類名稱",
        "A7684E@isShow": "是否開放"
    },
    "backend_config_integral": {
        "BEC1AD@bonus_rate": "贈送積分比例",
        "0B80C2@deduce_rate": "積分扣除比例",
        "160EFC@isBonus": "是否開放贈送積分"
    },
    "backend_shop_supplierList": {
        "8F71F5@company_id": "公司ID",
        "995038@company_name": "公司名稱",
        "E919F3@super_user": "公司超級用戶",
        "5A75EE@Contact_person": "聯絡人",
        "F9618A@company_email": "公司郵箱",
        "65868F@company_phone": "公司電話",
        "760B82@company_status": "狀態",
        "51CC83@company_desc": "公司簡介(英文)",
        "D4ED09@registration_time": "註冊時間",
        "F703D1@company_name_hk": "公司名稱(中文)",
        "394FBB@company_name_en": "公司名稱(英文)",
        "B08D37@addr_hk": "地址(中文)",
        "EFBD4B@addr_en": "地址(英文)",
        "5AEC72@remark_hk": "備註(中文)",
        "EF978E@remark_en": "備註(英文)"
    },
    "backend_shopUser_supplierUser": {
        "E916BD@company_name": "公司名稱",
        "CB120A@user_id": "用戶ID",
        "26E957@username": "用戶名",
        "062848@nickname": "暱稱",
        "9CD9E0@status": "狀態",
        "B3BDE4@isSuper": "超級用戶",
        "82F60F@last_login_time": "最後登錄時間"
    },
    "backend_productClassify": {
        "CFB3A7@classify_id": "分類ID",
        "3CAC83@classify_name": "分類名稱",
        "ED8AF1@status": "狀態",
        "B39EE1@classify_photo": "分類圖片",
        "1CDE5B@classify_name_hk": "產品分類 (中文)",
        "667CB5@classify_name_en": "產品分類 (英文)",
        "EB6F82@add_fast": "快速添加分類",
        "F93B5D@default": "默認為頂級菜單"
    },
    "backend_emailList": {
        "1892CE@email_type": "郵箱類型",
        "A062DF@email_title": "郵件標題",
        "D7675B@send_address": "發送郵箱地址",
        "28EE7F@receive_address": "接收郵箱地址",
        "C31D79@isReaded": "是否已讀",
        "0C2F58@send_time": "發送時間",
        "B1D7F4@read_time": "讀取時間"
    },
    "supplier_power_grouping": {
        "3FD60B@Rights_group": "權限組別",
        "C9B87D@grouping_id": "組別ID",
        "4AE8C4@Group_name": "全職/兼職",
        "A70BF8@super_administrator": "超級管理員",
        "0E5FF1@operation": "操作",
        "25BFF5@super_administrator_notmodify": "超級管理員不允許修改"
    },
    "supplier_power_childUser": {
        "DE359D@nickname": "暱稱",
        "825849@head_portrait": "頭像",
        "B2778D@super_user": "超級管理員",
        "AADC1A@LastLogin_Time": "上次登入時間",
        "F18109@state": "狀態",
        "65D941@Subuser_management": "用戶管理",
        "D9FF40@Last_login_IP": "上次登入IP"
    },
    "backend_dashboard": {"C8866F@order": "訂單", "BF8D82@unfinished_order": "未完成訂單", "FD5734@restaurant": "分店數量"},
    "supplier_submenu": {
        "AD3C65@commodity_management": "產品管理",
        "B77F3A@authority_management": "權限管理",
        "99DB4A@Setting": "設定",
        "12FC12@control_panel": "控制中心"
    },
    "supplier_supplier_produceManage": {
        "B486AA@delete_all_products":"刪除所有產品",
        "4E60F8@Management_products": "產品列表",
        "F12B2D@input_the_key_words": "輸入關鍵字",
        "C84B53@goods_ID": "產品ID",
        "8D36FA@classification_of_goods": "分類",
        "ADD410@Commodity_images": "圖片",
        "753635@price": "價格",
        "72E6C1@putaway": "有效",
        "4D7AF5@Quantity_unit": "單位",
        "B8BD33@product_name": "產品"
    },
    "restaurant_submenu": {
        "E0E5FB@dashboard": "控制中心",
        "1B2E2F@authrity": "權限管理",
        "BA20D2@Privilege_grouping": "權限組別",
        "896D7E@Sub_user": "用戶管理",
        "65D701@Operation_record": "操作記錄",
        "2C231F@store_management": "分店管理",
        "41AF69@Commodity_classification": "產品分類管理",
        "FA62B7@supplier": "供應商",
        "0F2640@supplier_list": "供應商名單",
        "8F6D4D@supplier_products": "供應商產品",
        "BFB6EB@commodity_attr": "產品規格",
        "D74010@procurement": "採購",
        "396A54@general_order": "訂單管理",
        "D6B9A2@Optional_products": "建立訂單",
        "2B68B6@b2b_product": "B2B產品",
        "E6A81C@Inventory": "存貨",
        "C58F2C@accounting": "會計",
        "59F88B@Integral_record": "積分記錄",
        "8F506E@company_info": "公司資料",
        "446088@setting": "設定",
        "5409DA@add_supplier": "編輯供應商",
        "6B6530@add_supplier_editSupProduct": "编辑供应商品",
        "90D6D1@order_det": "訂單詳情"
    },
    "supplier_supplier_goodsAttribute": {
        "49D281@commodity_property": "產品規格",
        "E416AD@attribute_name": "產品規格",
        "D0456F@attribute_value": "種類",
        "3FB998@Attribute_name_hk": "規格名稱(中文)",
        "809D25@Attribute_name_en": "規格名稱(英文)"
    },
    "restaurant_supplier_supplierList": {
        "8C238E@name": "名稱",
        "E318B6@desc": "描述",
        "E84BAF@address": "地址",
        "43B05F@email": "電郵",
        "28AF0E@phone": "電話",
        "0666FD@pay_type": "結算方式",
        "5CA70A@min_order": "最低訂單金額",
        "8FC08B@reduction_conditions": "免費送貨金額",
        "2E31F9@fee": "運費",
        "4A7048@supplier_id": "供應商ID"
    },
    "supplier_partner": {
        "34C475@name": "名稱",
        "249A92@payment_method": "結算方式",
        "AADE98@Late_payment_day": "信貸期限",
        "A73300@Designated_monthly_statement_day": "每月結算日",
        "475422@partner": "合作夥伴",
        "C6DED8@partner_ID": "夥伴ID"
    },
    "restaurant_supplier_products": {
        "3B1C04@product_id": "產品ID",
        "DC5311@Product_classification": "分類",
        "CE8240@product_name": "產品",
        "7E39D8@img": "圖片",
        "C04851@price": "價格",
        "FEF412@supplier_name": "供應商",
        "B3EF6A@status": "有效",
        "5398AC@unit_number": "單位數量",
        "CDC4F4@unit": "商品單位"
    },
    "restaurant_store": {
        "6D46FE@store_id": "分店ID",
        "1DC508@contact": "聯絡人",
        "938F71@HongKong_SAR": "區域",
        "E99B03@name": "分店",
        "763B74@address": "分店地址",
        "0C4048@phone": "聯絡電話",
        "BA05EA@store_name_hk": "分店(中文)",
        "238DE5@store_name_en": "分店(英文)"
    },
    "supplier_order": {
        "E0C2CB@order_management": "訂單管理",
        "E5F50A@order_status": "訂單狀態",
        "46C89C@order_reference": "訂單編號",
        "CB13F3@type": "類型",
        "066E3C@shop": "分店"
    },
    "supplier_company_information": {
        "A5E131@whatsapp_group":"WhatsApp群組",
        "65F497@company_description": "公司簡介",
        "773745@contact_number": "聯絡電話",
        "65F49B@company_description": "公司簡介(中文)",
        "9B4616@company_address": "公司地址(中文)",
        "539097@company_information": "公司信息"
    },
    "supplier_TransactionSettings": {"CD4A38@delivery_fee": "免費送貨金額", "2435B2@Transaction_settings": "交易設定"},
    "restaurant_supplier_attr": {
        "14816B@attr_id": "規格ID",
        "F17085@attr_name": "產品規格",
        "72DFF1@attr_val": "種類",
        "D76F17@sort": "排序",
        "CF4A03@attr_name_hk": "規格名稱(中文)",
        "50B6CC@attr_name_en": "規格名稱(英文)",
        "DBE743@more_val": "請輸入属性值,多個屬性值請用英文分號分隔",
        "B6A194@default": "默認可不填寫"
    },
    "restaurant_buy_genner": {
        "30D594@order_id": "訂單編號",
        "FE6497@store": "分店",
        "7B0C54@type": "類型",
        "586E4F@supplier_name": "供應商名稱",
        "E0A3B6@order_price": "訂單總金額",
        "759F57@pay_status": "付款狀態",
        "17E296@order_status": "訂單狀態",
        "97F49A@created_order": "建立採購訂單",
        "99D144@type": "選擇類別",
        "13B04D@Optional_supplier": "自選供應商",
        "59739F@choose_supplier": "選擇供應商",
        "4C75AE@tips": "注意: 按建立後不能修改供應商",
        "452B30@created": "建立",
        "E3B214@place_order_user": "下單用戶",
        "47DC09@created_general": "採購訂單",
        "557AE0@select_store": "請選擇分店",
        "D6582C@add_goods": "添加商品",
        "D78E87@place_order": "下單"
    },
    "restaurant_buy_optionalOrder": {
        "283B63@product_id": "產品ID",
        "FFD749@product_img": "圖片",
        "7AB313@classify": "分類",
        "64F0A6@product_name": "產品",
        "1C9E17@supplier_name": "供應商",
        "9D943A@price": "價格",
        "118294@shopcart": "加入購物車",
        "F1FD94@Choose_specifications": "選擇規格",
        "2744C9@number": "數量",
        "C5B93F@color": "顏色",
        "861FA0@number_unit": "單位/數量"
    },
    "restaurant_buy_B2BProduct": {
        "B3EB47@product_id": "B2B產品ID",
        "6C52C8@product_name": "產品",
        "DCD279@sku_id": "B2B產品規格ID",
        "8BF399@sku_name": "產品規格",
        "EF1083@unit_price": "產品價格",
        "52D795@supplier_name": "供應商名稱",
        "2D3DAB@buy_time": "購買時間"
    },
    "restaurant_inventory": {
        "227D37@inventory_id": "存貨ID",
        "CF7A68@store": "分店",
        "0009CF@product_name": "產品",
        "198560@sku_name": "產品規格",
        "E5BE78@quantity_purchased": "購入商品數量",
        "812240@buy_quantity": "採購單位",
        "05A3D5@calculate_quantity": "轉換率",
        "98D94E@Inventory_quantity": "存貨數量",
        "307A4B@Inventory_unit": "存貨單位",
        "8E8BAB@calculate_unit": "存貨單位"
    },
    "restaurant_accouting_record": {
        "22C5BC@record_id": "記錄ID",
        "1B48BB@order_code": "訂單編號",
        "228DB4@integral_type": "積分操作類型",
        "7597E7@integral": "積分",
        "23DF28@record_time": "記錄時間",
        "C59B8B@increase": "增加",
        "E94CF8@reduce": "減少"
    },
    "restaurant_setting_companyInfo": {"A88F99@display_name_hk":"顯示名稱(中文)","A77F35@display_name_en":"顯示名稱(英文)","B056EF@email": "電郵", "D20198@telephone": "聯絡電話"},
    "restaurant_dashboard": {"E3BBED@order_request": "應付帳款", "27D6A5@unfinish": "未完成訂單", "11D38A@store": "供應商"},
    "supplier-setting-DistributionSet": {
        "0823DB@DistributionSet": "送貨設定",
        "6198C8@delivery_date": "送貨日期",
        "EB504B@Distribution_area": "送貨區域",
        "F1E87C@Select_date": "選擇日期",
        "1DB5AF@Select_region": "選擇地區",
        "F988E1@Select_region": "選擇地區"
    },
    "restaurant_supplier_addsuppluer": {
        "6BA9D8@name_hk": "供應商(中文)",
        "4A6809@name_en": "供應商(英文)",
        "0C63A5@linkman": "聯絡人",
        "AB65B0@cash_delivery": "貨到付款",
        "2FDEF9@Deferred_payment": "信貸期限",
        "4AF4CD@specify_date": "每月結算",
        "BF08D0@overdue_days": "信貸期限",
        "610635@specify_date": "每月結算"
    },
    "restaurant_supplier_addSupProduct": {
        "BAA03D@basic_tab": "基礎設置",
        "BF0A77@product_attr": "產品規格",
        "AF8EC7@name_hk": "產品名稱(中文)",
        "EA9E65@name_en": "產品名稱(英文)",
        "E9513A@product_name": "產品名稱",
        "015CB0@key": "關鍵詞",
        "183798@space": "以空格區分關鍵字",
        "DC9557@sort": "排序",
        "3E1559@clear_attr": "清空規格",
        "43EF6B@attr_name": "規格名稱",
        "16EBEC@price": "價格",
        "5A7BCB@number_unit": "單位數量/商品單位",
        "AAB37F@no_data": "暫無數據"
    },
    "shop_cart": {
        "23490B@shopcart": "購物車",
        "E65DF9@goods_img": "圖片",
        "4C8583@name": "名稱",
        "86F6A0@unit_price": "價格",
        "D3B150@number": "數量",
        "CB55C1@specifications": "產品規格",
        "936345@total_price": "總金額",
        "883AA6@send_date": "存貨",
        "7E9DE5@check_all": "全選",
        "C0D4AB@choosed": "已選產品",
        "A91ABF@order_price": "訂單金額",
        "1ED05B@rate": "免費送貨金額",
        "1AE43D@total": "合計",
        "C83C45@settlement": "結算"
    },
    "supplier-produceManage-publishProduct": {
        "2072FB@classify_id": "分類ID",
        "BD6C55@Name_hk": "名稱（中文）",
        "36F75F@name_en": "名称（英文）",
        "3B83F2@keyword": "關鍵字",
        "226509@Distinguish_keywords_by_Spaces": "以空格區分關鍵字",
        "785991@Introduction_en": "簡介(英文)",
        "14719A@Introduction_hk": "簡介(中文)",
        "92C10D@Shelf": "有效",
        "DBCC4F@Number_of_Units": "單位數量",
        "4E6A29@Commodity_unit": "單位",
        "97DB98@Minimum_purchase_quantity": "最少訂購數量",
        "EE6D61@Sort": "排序",
        "E400F7@Product_Image": "產品圖片",
        "B7148B@Basic_Settings": "基礎設置",
        "98875D@Product_specifications": "產品規格",
        "E9BF3B@Number_of_Units_Commodity_unit": "單位數量/商品單位",
        "D4430E@New_specifications": "新增規格",
        "799C97@Clear_specification": "清空規格"
    },
    "orderDetail": {
        "C0ED69@print": "打印",
        "F854E2@be_processed": "待處理",
        "87CE30@In_processing": "處理中",
        "7879F8@have_received": "已接收",
        "A119AD@has_payment": "已付款",
        "35ED62@order_closed": "訂單關閉",
        "AB7C91@invoice_number": "賬單編號",
        "845D3E@buyer": "購買人",
        "082549@order_time": "下單時間",
        "2D64A4@store_name": "分店",
        "2775BD@store_tele": "電話",
        "6F5A1A@delivery_adderss": "送貨地址",
        "30DB36@Payment_status": "付款狀態",
        "FF66B0@supplier": "供應商",
        "7FC644@supplier_tele": "供應商電話",
        "F6B332@Supplier_contact": "供應商聯絡人",
        "7CA68E@total_price": "總金額",
        "CAF2C7@number": "購買數量",
        "3BB7C2@Accept_number": "接收數量",
        "04B67B@Credits_deducted": "ChefPoints扣除金額",
        "98FF8B@next": "返回上一級",
        "EB17E9@Refuse_order": "拒絕訂單",
        "73FE8F@Cancel_order": "取消訂單",
        "7917E0@Accepted_quantity": "接收數量"
    },
    "shop-index": {
        "E96A88@index": "首頁",
        "9FD647@store": "功能",
        "ADD4F1@Please_enter_key_words": "請輸入關鍵字",
        "6449D6@product_category": "分類",
        "C8B82E@company": "公司",
        "BA8EF4@about_us": "關於我們",
        "6D3E8C@Privacy_Policy": "私隱條款",
        "F113A5@contac_us": "聯絡我們",
        "5D4405@Terms_and_conditions": "條款和條件",
        "3B0294@contact": "聯繫",
        "12AD58@phone": "電話",
        "D32B7A@time": "時間",
        "D72298@Monday_to_Friday": "週一至週五",
        "EEA554@Saturday_to_Sunday": "週六至週日",
        "382FFB@confirm_order": "確認訂單",
        "1235FF@modify_order": "修改訂單",
        "EACECB@Return_to_the_mall": "返回商城"
    },
    "shop-goods": {
        "BED8D2@goods": "網上市集",
        "88E57F@Minimum_order_amount": "最低訂購金額",
        "E6DC79@Full_reduction": "免費送貨金額",
        "A29F44@search": "搜尋",
        "D4F96B@kind": "種類",
        "5C23E3@Switch_language": "切換語言",
        "740AD3@login": "登入",
        "486EA7@More": "更多",
        "CFD43B@No_specifications": "暫無規格",
        "D33083@Remaining_points": "ChefPoints金額",
        "5F5919@Minimum_order_quantity_ratio": "最低訂貨量比",
        "EA3800@supplier_LOGO": "供應商LOGO",
        "731456@Supplier_profile": "供應商簡介",
        "1D4230@Fill_in_the_form": "填寫表格",
        "299596@Remarks": "備註",
        "B5507E@submit": "提交",
        "43461C@Receiving_part": "接收部分",
        "1988CD@Receiving_completed": "接收完成",
        "3D33DE@Please_tick_the_category": "請勾選類別！",
        "1213D7@Please_select_supplier": "請選擇供應商！",
        "FC4B28@Please_improve_the_product_specification_information": "請完善該商品規格信息！",
        "919A0F@Please_fill_in_the_quantity_of_the_product": "請填寫商品數量！",
        "8264A9@Add_to_Cart_successful": "加入購物車成功！",
        "91B7D6@please_log_in_first": "請先登錄！",
        "8BF492@Unchecked_items_cannot_be_placed": "未勾選商品不能下單！",
        "9DD40C@Please_choose_a_store": "請選擇分店！",
        "9A0301@Please_complete_the_account_password": "請將賬號密碼填寫完整！",
        "C8E0FC@Please_fill_in_the_group_name": "請填寫分組名稱！",
        "A16978@Please_select_a_role": "請選擇角色！",
        "2ECFD0@Confirm_delete": "確認刪除嗎？",
        "032EEE@Confirm_reset_password": "確認重置密碼嗎？",
        "768789@Confirm_receipt": "確認接收嗎？",
        "ABFDC5@Confirm_the_change": "確認更改嗎？",
        "16E8D3@Successful_operation": "操作成功！",
        "A906CE@Network_error_please_try_again_later": "網絡錯誤，請稍後重試！",
        "5E8023@Please_complete_the_form": "請將表單填寫完整！"
    },
    "restaurant_buy_print": {
        "9C5FBF@supplier_info": "供應商信息",
        "59DDF6@order_info": "訂單信息",
        "2DC8EA@product_info": "商品信息",
        "945C82@Print_immediately": "立即打印",
        "470F44@Latest_date": "付款截止日期",
        "B06A44@Branches_stores": "下單分店",
        "2886D5@Order_store_phone": "下單分店電話",
        "54AB78@Order_delivery_address": "下單送貨地址"
    },
    "aboutus": {
        "D5E161@modify_payment_status":"修改付款狀態",
        "06D38E@online": "專門接駁",
        "9C7F56@food": "餐廳和供應商的",
        "FE6F4C@ingredients": "第三方網上食材平台",
        "BE3023@join": "加入我們！",
        "A52D91@solve1": "很難找到合適的供應商 - 餐廳老闆總是需要依靠別人推薦或互聯網上有限的信息",
        "1D4E1A@solve2": "食材價格和質量缺乏透明度",
        "E1E269@solve3": "浪費時間和人力輸入每個採購訂單的數據",
        "458BFD@solve4": "人為錯誤導致採購訂單出錯",
        "4C7AB7@feature": "功能",
        "23DAB9@feature_title1": "採購管理",
        "6D1C81@feature1": "有系統地發出採購訂單和管理發票，避免人為錯誤，不再延遲處理或遺失訂單",
        "AD5C20@feature_title2": "後台數據分析",
        "893305@feature2": "監控日常成本最有效的方法， 只需點擊幾下，即可顯示各項詳細的數據分析",
        "9ACA2B@feature_title3": "網上食材平台",
        "7BDA32@feature_title4": "會員積分獎勵計劃",
        "CEA6B3@feature3": "助您尋找新的供應商，比較價格並通過我們的網上平台直接訂購，我們可以幫助您找到最合適的食材",
        "B49CB8@feature4": "透過ChefStation購買食材可獲取Chefpoints，然後將您的Chefpoints當現金使用",
        "1E0AA1@fee1-1": "網上下單",
        "FD8464@fee1-4": "會員積分獎勵",
        "F44CA0@fee2-1": "採購分析報告",
        "DB85C3@fee2-3": "產品價格和數量差異",
        "C41E8F@fee5": "採購訂單管理",
        "3A74F7@fee3-3": "供應商產品目錄",
        "36F318@fee7": "重複日常訂單",
        "4D7ADA@fee8": "公開招募",
        "C03B4A@partner": "我們的合作夥伴",
        "DD9192@fee_plan": "收費計劃",
        "907247@contact_us": "聯絡我們！",
        "57B601@info": "信息",
        "23CC4E@name": "姓名",
        "E928CD@issue": "專門解決",
        "C5B9F6@solve": "以下問題",
        "72F5FF@Minimum_order_quantity": "最少訂購數量",
        "B0A0B1@radio": "抽成比例",
        "BE94C4@fee3-4": "查看訂單付款狀態",
        "72AB93@Product_Details": "產品資料",
        "3DB928@Supplier_Details": "供應商資料",
        "935D28@ChefStation": "ChefStation",
        "1446FC@no_fee": "不收費用",
        "1020E4@no_fee1": "我們不會向餐廳收取任何費用",
        "6AD290@Current_Inventory": "目前存貨",
        "CE2CE2@Historical-stock": "歷史存貨",
        "BAABFA@Confirm_the_modification": "確認修改嗎",
        "CCBA90@Confirm_cancellation": "確認取消嗎",
        "098C3F@all_products": "所有產品",
        "351BB4@All_suppliers": "供應商",
        "49CA13@personal": "個人",
        "9C51BD@E-mail_order": "E-mail下單",
        "D4D3E7@chat": "聊天",
        "AF9C99@month_order_money": "當月每日的下單金額",
        "CD5BE2@month_money_top10": "本月銷售額前十產品",
        "405894@month_revice_top10": "本月銷售量前十產品",
        "15D285@chart": "統計圖",
        "374FC5@month_order_all": "本月訂單",
        "A01056@order_change": "訂單價格/數量變化",
        "09AE4A@primary_account": "主賬號",
        "6A7E47@initial_unit": "下單價格",
        "2DF5D9@pay_unit": "接收價格",
        "03863E@last_date": "最遲付款日",
        "B0F833@order_time": "日期",
        "0390DF@difference": "差別",
        "58431A@chatroom": "聊天室",
        "9E51D1@Order_item_amount_difference": "採購金額變化",
        "412295@Order_quantity_difference": "採購數量變化",
        "C44096@Historical_inventory_ID": "歷史存貨ID",
        "3482AB@Recording_time": "記錄時間",
        "13C31B@modification_time": "修改時間",
        "C3BE54@admin": "管理員",
        "662D36@staff": "員工",
        "CEECB1@is_edit": "是否允許編輯",
        "A94527@spec_id": "規格ID",
        "A97E8F@Description": "簡介",
        "3A6FE5@product_num": "產品數量",
        "96F4F8@product_price": "產品價格",
        "CBD374@total_price": "總金額",
        "482D27@commodity_attr": "公司地址(中文)",
        "9E0005@order_min": "免費送貨金額",
        "1886CC@fee": "運費",
        "F31309@fee_ways": "付款方式",
        "E56154@user_name": "用戶名稱",
        "1DFF41@Send_PO": "發出訂單",
        "87F6C1@Balance": "ChefPoints餘額",
        "E820CB@Estimated_delivery_time": "存貨",
        "CB7991@Wrong_estimated_delivery_time": "預計送貨時間填寫有誤",
        "B69FB2@order_time": "訂單時間",
        "6DFA47@Delivery_status": "送貨狀態",
        "E37C0F@common_problem": "常見問題",
        "DE7FD1@restaurant_contact": "餐廳聯絡人",
        "37A57E@restaurant_phone": "餐廳電話",
        "29B8DC@top10_restaurant": "下單金額前十餐廳",
        "D4D5D3@day": "天",
        "F32A9F@company_addr": "公司地址(英文)",
        "8F4A3C@company_logo": "公司Logo",
        "A8C409@Terms_of_use": "使用條款",
        "99A454@monday": "星期一",
        "6FC140@Tuesday": "星期二",
        "7FE14B@Wednesday": "星期三",
        "1C3563@Thursday": "星期四",
        "AA11D7@Friday": "星期五",
        "78EC72@Saturday": "星期六",
        "CA70D5@sunday": "星期日",
        "7F2D75@Cash_on_delivery": "貨到付款",
        "782FFE@Deferred_payment": "可延遲付款",
        "D0F1D6@Set_statement": "指定日期月結",
        "C7977E@Payment_Receieved": "已付款",
        "323AC0@Awaiting_Payment": "待付款",
        "3008EA@Add_to_the_optional_classification": "添加進自選分類",
        "3AA7E7@top10_category": "當月購買金額前十的分類",
        "45DF7E@Standard_stock": "標準存貨",
        "94396A@standard_value": "標準值",
        "C77370@Login": "登入",
        "6A20EF@forget_password": "忘記密碼",
        "4E7C2A@Please_add_items_first": "請先添加商品",
        "F0A61A@Marketplace": "網上市集",
        "EDC382@fee1-2": "獲取食材樣本",
        "870877@fee1-3": "獲取特別報價",
        "E16045@fee2": "數據分析",
        "D808AF@fee2-2": "存貨分析報告",
        "47AA8C@fee3": "採購管理系統",
        "4B9FED@fee3-1": "網上訂單管理",
        "EE182D@fee3-2": "一般訂單管理（透過WhatsApp或電郵）",
        "B4F293@Request_sample": "索取樣本",
        "375CE0@Request_a_special_offer": "索取特別報價",
        "915ECD@General_order": "一般訂單",
        "0CBCA9@normal": "正常",
        "E0C2B9@lock": "封鎖",
        "980E0D@yes": "是",
        "A03514@no": "否",
        "14F845@chefpoints": "ChefPoints",
        "26D960@chefPoints_change": "ChefPoints 變動",
        "245620@Overdue": "已逾期",
        "572BF7@Order_Type": "訂單類形",
        "50BC07@forgot_pass": "忘記密碼？",
        "D453CE@Time_of_receipt": "收貨時間",
        "57925F@register": "註冊",
        "66D050@delivery_time": "送貨時間",
        "48C14D@supplier_platform": "供應商平台",
        "09C1E0@Total_amount_of_goods": "產品總金額",
        "E3A644@Order_quantity": "下單數量",
        "AEB42C@Use_ChefPoints": "使用ChefPoints",
        "E8221A@restaurant_name": "餐廳名稱",
        "BAF614@restaurant_chart1": "當月每日的下單金額",
        "3B33BC@restaurant_chart2": "本月銷售額前十產品",
        "E701FA@resaurant_chart3": "本月銷售量前十產品",
        "327C00@resaurant_chart4": "當月購買金額前十的分類",
        "6B3562@Marketplace_Order": "ChefStation Market訂單",
        "2AD773@Restaurant_Details": "餐廳資料",
        "41856B@supplier_block1": "未付款訂單",
        "871D88@Count_date": "盤點日期",
        "C9B86B@daily": "每日",
        "445AA1@Weekly": "每週",
        "B8F622@Opening_times": "營業時間",
        "C28C46@delivery_time": "送貨時間",
        "06BB26@original_password": "原密碼",
        "3F9881@new_password": "新密碼",
        "D904D5@repeat_password": "重複密碼",
        "3F05B9@equit": "退出帳號",
        "01F5E5@login_success": "登入成功",
        "2E2D92@Quantity_of_Order": "訂單數量",
        "035886@Repeat_Orders": "重複下單",
        "606AE7@login_repeated": "長時間未操作，請重新登入！",
        "9301F0@Quotation_Information": "報價資料",
        "7C0CF5@Upload_failed": "上傳失敗，圖片大小不能超過6MB！",
        "7C0CF6@Upload_failed": "上傳失敗，文檔大小不能超過30MB！",
        "0DBD9D@Failed_to_load": "加載失敗",
        "8DA31F@New_news": "新消息",
        "0CE600@Send_a_message": "發送消息",
        "88E89A@Return": "換到下一行",
        "2FB613@Send": "發送",
        "9397BF@Click_OK_to_Send_the_Picture": "點擊確認發送該圖片",
        "9397B8@Click_OK_to_Send_the_Doc": "點擊確認發送文件",
        "B36F4C@chat_record": "聊天記錄",
        "882633@No_Notice": "暫無通知",
        "2F9ADA@Mark_as_read": "全部標記為已讀",
        "3DC252@new_message": "條新消息",
        "25DB6F@account_number": "帳號",
        "273505@Refresh_permissions_manually": "手動刷新權限",
        "DF0FCE@In_Stock": "有貨",
        "18EF78@Book": "預定",
        "A5B775@Quantity_Status": "數量狀況",
        "D11489@Order_Deadline": "截單時間",
        "24F080@Bank_Name": "銀行",
        "1A4DE1@Account_Name": "收款人",
        "C232EA@Account_Number": "帳號",
        "2F1443@Bank_Info": "付款資料",
        "F98ACA@Constant": "恆常",
        "BCC0D3@my_favourite": "我的最愛",
        "D38FDE@Inoperable": "無法操作",
        "4E9CB1@Order_Combination": "訂單組合",
        "BBBC82@Group_ID": "全職/兼職",
        "06951D@Group_Name": "組別名稱",
        "0119CF@Number_of_Product_Groups": "組合內產品",
        "A7A890@Produc_ID": "組別產品ID",
        "900298@Please_Enter_a_Product_Name_or_Keyword": "請輸入產品名稱或關鍵詞",
        "742833@There_are_No_Products_in_This_Combination_Cannot_be_Added_to_Cart": "此組合無產品，無法加入購物車",
        "5454F8@Marketplace_Product": "網上市集產品",
        "D278B5@please_login_first": "訪問頁面失敗，請先登入!",
        "6505D3@Plan_Subscription": "訂閱計劃",
        "ED5B08@Plan_Name": "計劃名稱",
        "B4FA87@Number_of_planned_stores": "計劃分店數量",
        "C4970F@Basic_Cost": "基礎費用",
        "A32813@Exceeding_Planned_Store_Costs": "超出計劃門店費用",
        "8F1D59@Shelves": "是否上架",
        "7FF405@Plan_Name_English": "計劃名稱(英文)",
        "1F998B@Plan_Name_Chinese": "計劃名稱(中文)",
        "2F8186@Upload_failed": "上傳失敗，圖片大小不能超過5MB！",
        "23D2D3@Upload_failed": "上傳失敗，图片只能上傳jpg格式！",
        "8F2EAB@slide_photo": "輪播圖",
        "1C3EE0@log": "日誌",
        "C0DD27@regular_order": "定期下單",
        "47CFC2@timerId": "定時器Id",
        "88B1A4@timer_name": "定時器名稱",
        "33EAEC@date_type": "重複下單類形",
        "E0F102@range": "設定時間",
        "225A90@created_time": "創建時間",
        "6B52ED@monthly": "每月",
        "A85781@result": "執行結果",
        "DCF209@execution_time": "設定時間",
        "CAF889@day": "號",
        "C7C495@info": "執行信息",
        "075530@massUp": "上架/下架",
        "B76508@showProduct": "開放產品",
        "02B038@hidePRoduct": "隱藏產品",
        "981221@ecex_time": "執行時間",
        "0B8401@set_user": "設定用戶",
        "21AFC6@Product_No": "產品編號",
        "CEA76C@Product_unit": "產品單位",
        "47C789@Product_Unit_Name": "產品單位名稱(英文)",
        "840BD3@Product_Unit_Name": "產品單位名稱(中文)",
        "C9B5FF@Product_Unit_ID": "產品單位ID",
        "C676AA@The_operation_will": "操作將會修改所有產品的單位信息，是否確定修改？",
        "FFCC64@Are_you_sure_to_add": "是都確認新增？",
        "8FD945@please_log_in_again": "用戶信息缺失，請重新登入！",
        "CAAF92@Download_bulk_edit_Excel": "下載大量修改產品Excel",
        "DBF560@Upload_Bulk_edit_Excel": "大量修改上傳",
        "033E98@Please_Select_Restaurant": "請選擇餐廳",
        "5A698D@rights_reserved": "© 瀚星策略有限公司 版權所有",
        "F2C0FA@Partner_Product_ID": "合作夥伴產品ID",
        "111BDD@Original_Price": "原價格",
        "932932@special_price": "特別價格",
        "664E7E@Invalid": "無效",
        "0B6945@Cooperation_Products": "合作產品",
        "3AEBA8@My_Supplier": "我的供應商",
        "E9C731@ChefStation_Supplier": "The Market",
        "0467CD@WhatsApp_Order": "WhatsApp 下單",
        "66A5FF@Order_Notification_Type": "下單通知類型",
        "123991@Order_Type_First": "請先選擇訂單類型",
        "0FCD62@Inventory": "期初存貨",
        "CC731D@Orders": "採購量",
        "71DE08@Sales": "銷售量",
        "512DA9@Write_Off": "損耗量",
        "3F0589@Theoretical_Inventory": "應當存貨",
        "DDC645@Actual_Inventory": "期末存貨",
        "62183E@Variance": "變化",
        "EDBC7C@Wastage_Summary": "耗費量",
        "44863D@Report": "報告",
        "6AB704@Write_Off": "注銷量",
        "4849F7@Stock_date": "庫存日期",
        "C9ECA3@Issue_PO": "建立採購訂單",
        "1B8D6E@Order_by_Product": "按產品下單",
        "736349@Branch_First": "請先選擇分店",
        "144975@Accounting_Entry": "入賬",
        "9B9285@Bill_Classification": "帳單分類",
        "AFA01A@sub-categories": "是否允許有子分類",
        "3C1FAD@Revenue": "收入",
        "B4B0FE@Total_revenue": "總收入",
        "C70D90@Cost_of_Sales": "採購成本",
        "733F4F@Total_Cost_of_Sales": "總採購成本",
        "F16C06@Gross_Profit": "毛利润",
        "21F1F5@GP_Margin": "毛利率",
        "5C09FA@Expenses": "支出",
        "29AA04@Total_Expenses": "總支出",
        "183E01@Profit_Before_Tax": "稅前淨利潤",
        "ADC948@Profit_Margin": "稅前淨利率",
        "DE6DD7@Add_Record": "新增記錄",
        "7CD35D@New_Category": "修改分類",
        "B9DAAB@Export_Bill": "損益表",
        "381E69@Date": "日期",
        "FE0DEB@Amount": "金額",
        "D2772D@Cannot_be_Modified": "無法修改",
        "60E2D6@On_Timing": "開啟定時",
        "A9AC5D@Please_Select": "請選擇時間",
        "A446F9@Product_changes": "採購變化",
        "EEC3D3@Substandard_Inventory_Products": "標準存貨",
        "95A642@Products_In_Total_Amount": "採購金額排行",
        "C991DB@Receive_Top_Ten_Products": "採購數量排行",
        "0026F6@Purchase_Amount": "產品分類排行",
        "62F376@Top_Ten_Vendors_Purchased": "供應商排行",
        "6B0C8F@Loss_Percentage": "損耗率",
        "59395F@Inventory_value": "存貨金額",
        "5E837C@Export_report": "導出報告",
        "2AECD0@Export_Order": "導出 Excel",
        "5F8C9F@Purchasing_Price": "採購金額",
        "DD54D2@Product_Chart": "採購分析",
        "12AAC8@profit": "毛利潤",
        "F12209@analysis": "分析",
        "7EF062@Default": "默認",
        "AB78A8@Day": "日",
        "420F01@month": "月",
        "480440@season": "季",
        "B52CFC@year": "年",
        "14C616@the_first_season": "第一季度",
        "5AD174@Second_quarter": "第二季度",
        "49F8FC@Third_Quater": "第三季度",
        "F0305E@Fourth_quarter": "第四季度",
        "74A5F4@sum": "購買金額餐廳排行",
        "F5021B@Target_Price": "目標價格",
        "1F9F16@Staff": "員工",
        "41421F@Staff_Type": "全職/兼職",
        "8AE12E@Income_breakdown": "收入明細",
        "447E60@Employee_ID": "員工編號",
        "8620F5@Username": "用戶名",
        "25FC31@Title": "職位",
        "DD713B@Name": "名稱（簡稱）",
        "BAB644@Date_of_Birth": "出生日期",
        "B1BDBB@Address": "地址",
        "687DD4@Employee_title": "組別",
        "5BAB8D@Full": "全職",
        "47D798@part_time": "兼職",
        "A22EBE@Monthly": "每小時",
        "357941@hourly_rate": "月薪資",
        "F8647E@Hire_date": "入職日期",
        "3F5BBF@Leave_date": "離職日期",
        "E9690C@Salary_type": "薪水類型",
        "E0CB0A@Salary": "薪金",
        "C56440@Open_area": "開區",
        "4D4C0B@shut_down": "關閉",
        "B58C96@Place_an_order": "重複下單",
        "5429E6@Supplier_Type": "供應商類型",
        "71E150@Search_combination_name": "搜尋組合名稱",
        "578A62@Search_Supplier_Name": "搜尋供應商名稱",
        "F9CC44@Total_amount_of_goods": "商品總金額",
        "22B84B@reference_number": "參考編號",
        "AB134B@Payee": "收款人",
        "C97519@supplier_no": "供應商ID",
        "5C6796@Food_cost": "食材成本",
        "7D8E82@Schedule": "編更表",
        "4712AC@Overtime_pay": "加班費 (小時)",
        "76605B@hour": "小時",
        "BADE55@Job_Type": "上班類型",
        "1C32A1@Work": "上班",
        "FC1FB9@Official_Holiday": "例假",
        "8DAD0F@sick_leave": "病假",
        "CC1840@Annual_Leave": "年假",
        "FFECA8@Total_arrangement_hours": "本月工作時數",
        "327289@Overtime": "加班時長",
        "48E7FC@cost": "成本",
        "476677@Employee_working_hours_per_month": "員工每月工作時長",
        "832B7C@Not_due": "未到期",
        "7B072D@Overdue": "已過期",
        "ADE56E@Employee_salary": "薪金",
        "EE7271@Select_month": "選擇月",
        "2A0D3F@Working_hours": "上班時間",
        "E96BBF@Normal_time_salary": "正常時間薪資",
        "3F765C@Overtime_pay": "加班費",
        "D7E9FD@Additional_charges": "額外調整",
        "84242F@Total_salary": "總薪資",
        "C70759@Employee_MPF": "員工MPF",
        "A20A57@Employer_MPF": "僱主MPF",
        "467CD2@one_fix": "修改",
        "BF70BE@Break_time": "休息時間",
        "A9A7F4@Open_the_schedule": "開啟編更表",
        "CF6BE6@mon": "/月",
        "C7FD6E@hours": "/小時",
        "31B467@Arrange_time": "安排時間",
        "696FE4@Start": "開始",
        "44C071@End": "結束",
        "E82D58@Pleas_selected_type": "請將已選類型項的時間填寫完整",
        "A627ED@Arrangement_type": "安排類型",
        "01D9C2@batch_modify": "批量修改",
        "A6801E@Change_time": "編更時間",
        "BA6AE5@Unpaid_order_amount": "未付款金額",
        "2CF1A8@Total_Purchase_Amount": "採購金額",
        "7BE40B@Top_five_purchases": "購買金額前五的商品",
        "6F41DA@Accept_top_five_products": "接受數量前五的商品",
        "24F369@Top_5_categories_of_purchase_amount": "購買金額前五的分類",
        "C16F26@Top_5_suppliers_with_total_purchase_amount": "購買總金額前五的供應商",
        "BDBC1F@Loss": "損耗量",
        "8B9C12@no_receive": "未收貨訂單",
        "355446@Monthly_salary_hourly_salary": "月薪/時薪",
        "2EFE79@30_profit": "最近30天利潤",
        "8CBEFD@my_restaurant": "我的餐廳",
        "B60983@topfive_30": "採購金額排行前五 (過去30日)",
        "F89D2B@top5_30": "採購數量排行前五 (過去30日)",
        "E558D5@top5_30": "產品分類排行前五 (過去30日)",
        "9703BB@top5_30": "供應商排行前五 (過去30日)",
        "C32928@edit": "修改權限",
        "9E728C@salary": "薪金 (月/小時)",
        "AB0929@side_bar": "產品分類",
        "119D08@side_bar": "員工類型",
        "F3F8D5@sup_addr": "地址(中文)",
        "CB7169@sup_addr": "地址 (英文)",
        "D1BE99@bank2": "銀行 2",
        "9BE18D@bank2": "收款人 2",
        "F75A75@bank2": "帳號 2",
        "B003A6@This_is_required": "此項為必填項",
        "B7722D@Supplier_Address": "地址",
        "27721E@type": "訂單類型",
        "0AB255@Inventory": "存貨",
        "80E0A4@branch_addr": "分店地址 (英文)",
        "CF24C8@branch_addr": "分店地址 (中文)",
        "6A5B3D@Daily_break": "每天落場時間 (小時)",
        "59C826@time": "建立時間",
        "4AF32C@repeat_time": "重複下單時間",
        "DC552B@Awaiting": "待確認",
        "7A52EA@order_date": "下單日期",
        "95BD63@receive_date": "收貨日期",
        "3697EB@Blog": "博客",
        "E7F9ED@Blog_type": "博客類型",
        "B99A84@Blog_list": "博客列表",
        "0176C5@Author": "作者",
        "07C2A1@Whether_to_publish": "是否發佈",
        "A57A84@grade": "等級",
        "43A2F7@Level_one": "等級一",
        "EE1777@Level_2": "等級二",
        "F86F61@Level_3": "等級三",
        "259FAD@blog": "博客",
        "D1060F@read_more": "閱讀更多內容",
        "E2B943@blog1": "一同暢享台灣美食吧",
        "C20495@blog2": "為您集結所有當地美食和最愛餐廳",
        "BCCCBD@Content_Chinese": "內容(中文)",
        "D6AC22@Content_English": "內容(英文)"
    }
}
