<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-23 18:30:15
 * @LastEditTime: 2019-10-18 15:12:59
 * @LastEditors: Please set LastEditors
 -->
<template>
  <!-- 没有子菜单 -->
  <li
    v-bind:style="{ display: hasRoutePerms(perm)?'block':'none'}"
    v-if="!childrenLinks && isHeader"
    :class="{ headerLink: true, className }"
  >
    <router-link :to="link">
      <span class="icon">
        <i :class="fullIconName"></i>
      </span>
      {{ header }}
      <sup v-if="label" class="headerLabel">{{ label }}</sup>
      <b-badge v-if="badge" class="badge rounded-f" variant="warning" pill>{{ badge}}</b-badge>
    </router-link>
  </li>
  <!-- 有子菜单 -->
  <li
    v-bind:style="{ display: perm ? 'block':'none'}"
    v-else-if="childrenLinks"
    :class="{ headerLink: true, className }"
  >
    <div @click="() => togglePanelCollapse(link)">
      <router-link :to="link" event class="d-flex">
        <span class="icon">
          <i :class="fullIconName"></i>
        </span>
        <span>{{ header }}</span>
        <div :class="{ caretWrapper: true, carretActive: isActive }">
          <i class="fa fa-angle-left" />
        </div>
      </router-link>
    </div>
    <b-collapse :id="'collapse' + index" :visible="isActive">
      <ul>
        <NavLink
          v-for="link in childrenLinks"
          :key="link.link"
          :header="link.header"
          :link="link.link"
          :perm="link.perm"
        >
          <span class="icon"></span>
        </NavLink>
      </ul>
    </b-collapse>
  </li>

  <!-- 子菜单 -->
  <li v-bind:style="{ display: hasRoutePerms(perm)?'block':'none'}" v-else>
    <router-link :to="index !== 'menu' && link">
      <i class="fa fa-arrow-right"></i>
      {{ header }}
    </router-link>
  </li>
</template>

<script>
import { mapActions } from "vuex";
import { hasRoutePermission } from "../../../permission/index.js";
export default {
  name: "NavLink",
  props: {
    badge: { type: String, dafault: "" },
    header: { type: String, default: "" },
    iconName: { type: String, default: "" },
    headerLink: { type: String, default: "" },
    link: { type: String, default: "" },
    childrenLinks: { type: Array, default: null },
    className: { type: String, default: "" },
    isHeader: { type: Boolean, default: false },
    deep: { type: Number, default: 0 },
    activeItem: { type: String, default: "" },
    label: { type: String },
    index: { type: String },
    perm: { type: Number, default: 0 }
  },
  data () {
    return {
      headerLinkWasClicked: true
    };
  },
  methods: {
    ...mapActions("layout", ["changeSidebarActive"]),
    togglePanelCollapse (link) {
      this.changeSidebarActive(link);
      this.headerLinkWasClicked =
        !this.headerLinkWasClicked || !this.activeItem.includes(this.index);
    },
    hasRoutePerms: function (perm) {
      return hasRoutePermission(perm);
    }
  },
  computed: {
    fullIconName () {
      return `fa ${this.iconName}`;
    },
    isActive () {
      return (
        this.activeItem &&
        this.activeItem.includes(this.index) &&
        this.headerLinkWasClicked
      )
    }
  }
};
</script>

<style src="./NavLink.scss" lang="scss" scoped />
