import ReconnectingWebSocket from './reconnecting-websocket.min'
import { getlocalStorage } from '../utils/localStorage'
import md5 from '../utils/md5';
export default {
    websocket: null,
    initWebSocket() { //初始化weosocket
        let baseURL;
        // const devUrl = "ws://chefstation.hexj.xyz:9501";
        const devUrl = "ws://api.chefstation.com.hk:9501";
        // const proUrl = "ws://chefstation-api.qlclient.com:9601/";
        const proUrl = "wss://api.chefstation.com.hk:9501";
        process.env.NODE_ENV === "development"
            ? (baseURL = devUrl)
            : (baseURL = proUrl);

        this.websocket = new ReconnectingWebSocket(baseURL);

        //实例化websocket
        // this.websocket.onmessage = function (msg) {
        //     // callback(msg)
        //     console.log(msg);

        // }
        this.websocketOpen();
        this.websocketonmessage();
        this.websocketclose();
        this.websocketerror();
    },

    //数据接收
    websocketonmessage(callback) {
        if (this.websocket) {
            this.websocket.onmessage = function (msg) {
                // 判断登录态是否失效
                if (callback) {
                    callback(msg)
                }
            }
        } else {
            setTimeout(() => {
                this.websocketonmessage(callback)
            }, 1000);
        }

    },
    //数据发送
    websocketsend(params) {
        let token = getlocalStorage("suppliertoken");

        let lang = getlocalStorage("lang") || "hk";
        function sortNumber(a, b) {
            return a - b
        }
        let authmd5 = md5(JSON.stringify(JSON.parse(getlocalStorage('supplierroute_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierbtn_id')).sort(sortNumber)) + JSON.stringify(JSON.parse(getlocalStorage('supplierAuth_id_json')).sort(sortNumber)))
        const data = JSON.stringify({
            token,
            lang,
            authmd5,
            ...params
        })
        this.websocket.send(data);
    },
    //关闭
    websocketclose(callback) {
        this.websocket.onclose = function () {
            console.log("已关闭");
            if (callback) {
                callback()
            }
        }
    },
    websocketOpen(callback) {
        this.websocket.onopen = () => {
            this.FirstConnection()
            if (callback) {
                callback()
            }
        }
    },
    websocketerror(callback) {
        this.websocket.onerror = function (res) {
            console.log("错误", res);
            if (callback) {
                callback()
            }
        }
    },
    FirstConnection() {
        let params = {
            push_type: "connect",
            room_id: "",
            content: "",
            code: ''
        };
        console.log("连接成功");
        this.websocketsend(params)
        console.log('第一次连接');
    }
}
